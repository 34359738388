import { FC } from 'react';
import Tooltip from '../Tooltip';
import styles from './Ellipsis.module.scss';

interface IEllipsis {
  phrase: string;
  maxLength: number;
  maxTooltipWidth?: number;
  className?: string;
}

const Ellipsis: FC<IEllipsis> = ({
  phrase,
  maxLength,
  maxTooltipWidth,
  className,
}) => {
  if (phrase.length > maxLength) {
    return (
      <Tooltip
        title={phrase}
        overlayInnerStyle={maxTooltipWidth
          ? { maxWidth: `${maxTooltipWidth}px` }
          : undefined
        }
      >
        <div
          className={className ? className : styles['phraseWrapper']}
        >
          {`${phrase.slice(0, maxLength - 3)}...`}
        </div>
      </Tooltip>
    );
  }

  return (
    <div className={className || ''} >{phrase}</div>
  );
};

export default Ellipsis;
