import { ActionsUnion, Action, createAction } from 'Utils/actionHelper';
import { INotificationData } from 'Data/interfaces/Notification/INotificationResponse';
import { IFileHistory } from 'Data/interfaces/Notification/INotificationRequest';
import { IUpdateUnreadNotification, IFilterDateOption } from './interfaces/INotificationState';
import { INotificationsMapAux } from 'Data/interfaces/Notification/INotificationsMapAux';
import { IReadNotificationTrackingRequest } from 'Data/interfaces/Notification/IReadNotificationTrackingRequest';

export enum NotificationActionKeys {
  LIST_NOTIFICATIONS_REQUEST = 'LIST_NOTIFICATIONS_REQUEST',
  LIST_NOTIFICATIONS_SUCCESS = 'LIST_NOTIFICATIONS_SUCCESS',
  LIST_NOTIFICATIONS_FAILED = 'LIST_NOTIFICATIONS_FAILED',
  LIST_NOTIFICATION_UPDATE = 'LIST_UNREAD_NOTIFICATION_UPDATE',

  LIST_NEW_NOTIFICATIONS_REQUEST = 'LIST_NEW_NOTIFICATIONS_REQUEST',
  LIST_NEW_NOTIFICATIONS_SUCCESS = 'LIST_NEW_NOTIFICATIONS_SUCCESS',
  LIST_NEW_NOTIFICATIONS_FAILED = 'LIST_NEW_NOTIFICATIONS_FAILED',
  LIST_NEW_NOTIFICATION_UPDATE = 'LIST_NEW_NOTIFICATION_UPDATE',

  SET_NOTIFICATION_MAP = 'SET_NOTIFICATION_MAP',
  UPDATE_NOTIFICATION_READ = 'UPDATE_NOTIFICATION_READ',
  UPDATE_NOTIFICATION_MAP = 'UPDATE_NOTIFICATION_MAP',
  SET_NOTIFICATION_AS_VIEW = 'SET_NOTIFICATION_AS_VIEW',
  SET_NOTIFICATION_AS_READ = 'SET_NOTIFICATION_AS_READ',

  CLEAR_ALL_NOTIFICATION = 'CLEAR_ALL_NOTIFICATION',

  SET_NOTIFICATION_READ_REQUEST = 'SET_NOTIFICATION_READ_REQUEST',
  SET_NOTIFICATION_READ_SUCCESS = 'SET_NOTIFICATION_READ_SUCCESS',
  SET_NOTIFICATION_READ_FAILED = 'SET_NOTIFICATION_READ_FAILED',

  GET_FILE_VERSION_HISTORY_REQUEST = 'GET_FILE_VERSION_HISTORY_REQUEST',
  GET_FILE_VERSION_HISTORY_SUCCESS = 'GET_FILE_VERSION_HISTORY_SUCCESS',
  GET_FILE_VERSION_HISTORY_FAILED = 'GET_FILE_VERSION_HISTORY_FAILED',

  TOOGLE_SHOW_FILTER_NOTIFICATION = 'TOOGLE_SHOW_FILTER_NOTIFICATION',
  TOOGLE_SHOW_FILTER_USERS = 'TOOGLE_SHOW_FILTER_USERS',
  TOOGLE_SHOW_FILTER_DATA = 'TOOGLE_SHOW_FILTER_DATA',

  FILTER_DATA_OPTION = 'FILTER_DATA_OPTION',
  CHOSE_FILTER_DATE = 'CHOSE_FILTER_DATE'
}

export const NotificationActions = {
  listNotification: (): ListAllNotification =>
    createAction(NotificationActionKeys.LIST_NOTIFICATIONS_REQUEST),
  listNotificationSuccess: (notificationResponse: INotificationData[]) =>
    createAction(NotificationActionKeys.LIST_NOTIFICATIONS_SUCCESS, notificationResponse),
  listNotificationFailed: (error: string) =>
    createAction(NotificationActionKeys.LIST_NOTIFICATIONS_FAILED, error),
  listUpdateNotification: (newNotification: IUpdateUnreadNotification): ListUnreadNotificationUpdate =>
    createAction(NotificationActionKeys.LIST_NOTIFICATION_UPDATE, newNotification),

  listNewNotification: (): ListNewNotification =>
    createAction(NotificationActionKeys.LIST_NEW_NOTIFICATIONS_REQUEST),
  listNewUpdateNotification: (newNotification: IUpdateUnreadNotification): ListNewNotificationUpdate =>
    createAction(NotificationActionKeys.LIST_NEW_NOTIFICATION_UPDATE, newNotification),
  listNewNotificationSuccess: (notificationResponse: INotificationData[]) =>
    createAction(NotificationActionKeys.LIST_NEW_NOTIFICATIONS_SUCCESS, notificationResponse),
  listNewNotificationFailed: (error: string) =>
    createAction(NotificationActionKeys.LIST_NEW_NOTIFICATIONS_FAILED, error),

  setNotificationMap: (notificationMap: INotificationsMapAux[]) =>
    createAction(NotificationActionKeys.SET_NOTIFICATION_MAP, notificationMap),
  updateNotificationMap: (notificationMap: INotificationsMapAux) =>
    createAction(NotificationActionKeys.UPDATE_NOTIFICATION_MAP, notificationMap),
  updateNotificationRead: (): UpdateNotificationReadProps =>
    createAction(NotificationActionKeys.UPDATE_NOTIFICATION_READ),
  setNotificationAsView: (notifications: INotificationsMapAux[]) =>
    createAction(NotificationActionKeys.SET_NOTIFICATION_AS_VIEW, notifications),
  setNotificationAsRead: (notificationsId: number[]) =>
    createAction(NotificationActionKeys.SET_NOTIFICATION_AS_READ, notificationsId),

  clearAllNotification: (): ClearAllNotification =>
    createAction(NotificationActionKeys.CLEAR_ALL_NOTIFICATION),

  setReadNotification: (request: IReadNotificationTrackingRequest): SetReadNotification =>
    createAction(NotificationActionKeys.SET_NOTIFICATION_READ_REQUEST, request),
  setReadNotificationSuccess: (readNotification: number[]): SetReadNotificationSuccess =>
    createAction(NotificationActionKeys.SET_NOTIFICATION_READ_SUCCESS, readNotification),
  setReadNotificationFailure: (error: string): SetReadNotificationFailed =>
    createAction(NotificationActionKeys.SET_NOTIFICATION_READ_FAILED, error),

  getFileVersionHistory: (request: IFileHistory): GetFileVersionHistory =>
    createAction(NotificationActionKeys.GET_FILE_VERSION_HISTORY_REQUEST, request),
  getFileVersionHistorySuccess: (data: INotificationData[]): GetFileVersionHistorySuccess =>
    createAction(NotificationActionKeys.GET_FILE_VERSION_HISTORY_SUCCESS, data),
  getFileVersionHistoryFailed: (error: string): GetFileVersionHistoryFailed =>
    createAction(NotificationActionKeys.GET_FILE_VERSION_HISTORY_FAILED, error),

  setShowNotification: (visible: boolean): ToogleShowFilterNotification =>
    createAction(NotificationActionKeys.TOOGLE_SHOW_FILTER_NOTIFICATION, visible),
  setShowNotificationUsers: (visible: boolean): ToogleShowFilterUsers =>
    createAction(NotificationActionKeys.TOOGLE_SHOW_FILTER_USERS, visible),
  setShowNotificationData: (visible: boolean): ToogleShowFilterData =>
    createAction(NotificationActionKeys.TOOGLE_SHOW_FILTER_DATA, visible),

  choseFilterDate: (value: number): ChoseFilterDate =>
    createAction(NotificationActionKeys.CHOSE_FILTER_DATE, value)
};

export type NotificationActionUnion = ActionsUnion<typeof NotificationActions>;
export type ListAllNotification = Action<NotificationActionKeys.LIST_NOTIFICATIONS_REQUEST>;
export type ListAllNotificationSuccess = Action<NotificationActionKeys.LIST_NOTIFICATIONS_SUCCESS, INotificationData[]>;
export type ListAllNotificationFailed = Action<NotificationActionKeys.LIST_NOTIFICATIONS_FAILED, string>;
export type ListUnreadNotificationUpdate = Action<NotificationActionKeys.LIST_NOTIFICATION_UPDATE, IUpdateUnreadNotification>;

export type ListNewNotification = Action<NotificationActionKeys.LIST_NEW_NOTIFICATIONS_REQUEST>;
export type ListNewNotificationSuccess = Action<NotificationActionKeys.LIST_NEW_NOTIFICATIONS_SUCCESS, INotificationData[]>;
export type ListNewNotificationFailed = Action<NotificationActionKeys.LIST_NEW_NOTIFICATIONS_FAILED, string>;
export type ListNewNotificationUpdate = Action<NotificationActionKeys.LIST_NEW_NOTIFICATION_UPDATE, IUpdateUnreadNotification>;

export type SetNotificationMapAux = Action<NotificationActionKeys.SET_NOTIFICATION_MAP, INotificationsMapAux[]>;
export type UpdateNotificationMapAux = Action<NotificationActionKeys.UPDATE_NOTIFICATION_MAP, INotificationsMapAux>;
export type UpdateNotificationReadProps = Action<NotificationActionKeys.UPDATE_NOTIFICATION_READ>;

export type SetNotificationAsView = Action<NotificationActionKeys.SET_NOTIFICATION_AS_VIEW, INotificationsMapAux[]>;

export type SetNotificationAsRead = Action<NotificationActionKeys.SET_NOTIFICATION_AS_READ, number[]>;

export type GetFileVersionHistory = Action<NotificationActionKeys.GET_FILE_VERSION_HISTORY_REQUEST, IFileHistory>;
export type GetFileVersionHistorySuccess = Action<NotificationActionKeys.GET_FILE_VERSION_HISTORY_SUCCESS, INotificationData[]>;
export type GetFileVersionHistoryFailed = Action<NotificationActionKeys.GET_FILE_VERSION_HISTORY_FAILED, string>;

export type ClearAllNotification = Action<NotificationActionKeys.CLEAR_ALL_NOTIFICATION>;

export type SetReadNotification = Action<NotificationActionKeys.SET_NOTIFICATION_READ_REQUEST, IReadNotificationTrackingRequest>;
export type SetReadNotificationSuccess = Action<NotificationActionKeys.SET_NOTIFICATION_READ_SUCCESS, number[]>;
export type SetReadNotificationFailed = Action<NotificationActionKeys.SET_NOTIFICATION_READ_FAILED, string>;

export type ToogleShowFilterNotification = Action<NotificationActionKeys.TOOGLE_SHOW_FILTER_NOTIFICATION, boolean>;
export type ToogleShowFilterUsers = Action<NotificationActionKeys.TOOGLE_SHOW_FILTER_USERS, boolean>;
export type ToogleShowFilterData = Action<NotificationActionKeys.TOOGLE_SHOW_FILTER_DATA, boolean>;

export type FilterDataOption = Action<NotificationActionKeys.FILTER_DATA_OPTION, IFilterDateOption[]>;
export type ChoseFilterDate = Action<NotificationActionKeys.CHOSE_FILTER_DATE, number>;

