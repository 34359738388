import { FC, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Button from '../../UI/Button';
import { Icon } from '../../UI/Icon';
import Profile from './Profile/Profile';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import UpgradePremium from './Modals/UpgradePremium';
import AvisoUpgradeConfirmacao from './Modals/AvisoUpgradeConfirmacao';
import { MinhaContaActions } from 'Store/MinhaConta/MinhaConta.actions';
import Notification from './Notification';
import { NotificationActions } from 'Store/Notification/Notification.actions';
import { history } from 'Store';
import CompletarCadastro from './CompletarCadastro/CompletarCadastro';
import { Mixpanel } from 'Utils/MixPanel';
import { getCurrentTenant } from 'Store/Tenant/Tenant.selector';
import { Divider, NavContainer } from './styles';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import NewNotification from './NewNotification';

export interface ITopBar {
  onCollapseClick: () => void;
  isCollapsed: boolean;
}

const TopBar: FC<Props> = ({
  showModalUpdatePremium,
  showModalConfirmUpdatePremium,
  onCollapseClick,
  isCollapsed,
  isLoading,
  newNotifications,
  planFree,
  userInfo: userInforGet,
  userLoading,
  updateEmpresaLoading,
}) => {
  const oldNotifFeatureFlag = useFeatureFlag('old-api-notif-feature');
  const oldNotifFeature = oldNotifFeatureFlag.enabled && oldNotifFeatureFlag.value === 'test';
  // const newNotifFeatureFlag = useFeatureFlag('new-api-notif-feature');
  const newNotifFeature = false;

  const dispatch = useDispatch();
  const currentTenant = useSelector(getCurrentTenant);

  const match = useRouteMatch<{ constructionSiteId: string }>([
    '/obras/detail/:constructionSiteId',
    '/obras/filelist/:constructionSiteId',
    '/obras/plotagens/:constructionSiteId',
    '/obras/plotagens/filelist/:constructionSiteId',
    '/obras/plotagens/filelist/receber/:constructionSiteId',
    '/obras/viewer/detail/:constructionSiteId',
    '/obras/activities/detail/:constructionSiteId',
    '/obras/panels/:constructionSiteId',
    '/obras/edit/:constructionSiteId',
  ]);
  const constructionSiteId = match?.params?.constructionSiteId
    ? Number(match.params.constructionSiteId)
    : null;

  const openUpgradePremium = () => {
    Mixpanel.track({
      name: 'CALL_T0_ACTION',
      props: {
        origin: 'top-bar-upgrade-button',
        originPath: window.location.pathname
      },
      userInfo: userInforGet,
      currentListTenant: currentTenant,
    });

    if (userInforGet?.CurrentRoleFk === 1) {
      history.push('/faturamento/planos');
    } else {
      window.open('https://bim.maletadoengenheiro.com.br/seja-premium');
    }
  };

  const closeUpgradePremium = () => {
    dispatch(MinhaContaActions.setShowModalUpdatePremium(false));
  };

  useEffect(() => {
    dispatch(NotificationActions.listNewNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavContainer iscollapsed={isCollapsed}>
        <div className="left">
          <div
            className="buttonCollapse"
            onClick={onCollapseClick}
          >
            <Icon icon="collapse" color="cinzaSuperior" customSize={12} />
          </div>

          {(!userInforGet?.isRegisterComplete && userInforGet?.CurrentRoleFk === 1) && (
            <CompletarCadastro
              icon="informacao"
              title="Complete seu cadastro"
              isLoading={isLoading || userLoading}
              userInfo={userInforGet}
              updateEmpresaLoading={updateEmpresaLoading}
            />
          )}
        </div>

        <aside>
          {planFree && !isLoading && (
            <Button
              type="primary"
              ispremium="true"
              onClick={() => openUpgradePremium()}
              className="buttonPremium"
            >
              <div>
                <Icon
                  icon="crown"
                  size="xxs"
                  className="crownPremium"
                />
                <span>Upgrade</span>
              </div>
            </Button>
          )}

          {!oldNotifFeature && !newNotifFeature && (
            <Notification notifications={newNotifications} />
          )}
          {!newNotifFeature && oldNotifFeature && (
            <NewNotification
              constructionSiteId={constructionSiteId}
              allNotifications={newNotifications}
              loadingExternalLink={false}
              userInfo={userInforGet}
              currentTenant={currentTenant}
            />
          )}
          {newNotifFeature && (
            <NewNotification
              constructionSiteId={constructionSiteId}
              allNotifications={newNotifications}
              loadingExternalLink={false}
              userInfo={userInforGet}
              currentTenant={currentTenant}
            />
          )}
          <Divider />

          <Profile
            name={userInforGet?.Nome || ''}
            image={userInforGet?.OriginalImageUrl || ''}
            thumb={userInforGet?.ThumbnailImageUrl}
            email={userInforGet?.Email || ''}
            hash={userInforGet?.UserHash || ''}
            userId={userInforGet?.Id || ''}
            isFreemium={planFree || true}
            tenantId={userInforGet?.CurrentTenantContext || 0}
            tenantUserRole={userInforGet?.CurrentRoleFk || 0}
            isLoading={isLoading}
            currentRole={userInforGet?.CurrentRoleFk}
            isSP={userInforGet?.isSP}
          />
        </aside>
      </NavContainer>

      {showModalUpdatePremium && (
        <UpgradePremium
          visible={showModalUpdatePremium}
          onCancel={closeUpgradePremium}
        />
      )}
      {showModalConfirmUpdatePremium && (
        <AvisoUpgradeConfirmacao
          visible={showModalConfirmUpdatePremium}
          onConfirm={() => dispatch(MinhaContaActions.setShowModalConfirmPremium(false))}
        />
      )}
    </>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  ...state.auth,
  showModalConfirmUpdatePremium: state.minhaConta.showModalConfirmUpdatePremium,
  showModalUpdatePremium: state.minhaConta.showModalUpdatePremium,
  registerCompleted: state.minhaConta.userInfo?.isRegisterComplete,
  newNotifications: state.notification.newNotifications,
  userLoading: state.auth.isLoading,
  updateEmpresaLoading: state.minhaConta.isLoading,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ITopBar;

export default connector(TopBar);
