import { ITopicDnDResponse } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { IComment } from "Data/interfaces/Activities/IComments";

export interface IComments {
  updateActivity: ITopicDnDResponse | null;
  loadingExternalLink: boolean;
  workComment: string | null;
}

export interface IVisibleRange {
  startIndex: number;
  endIndex: number;
};

export interface ICommentsStateReducer {
  copyingId: string | null;
  commentId: string | null;
  showDeleteModal: boolean;
  sentDelete: boolean;
  editComment: IComment | null;
  hasUpdate: boolean;
  removingId: string[] | null;
  visibleRange: IVisibleRange;
};

export enum EnumCommentsActionsReducer {
  SET_COPYING_ID = 'SET_COPYING_ID',
  SET_COMMENT_ID = 'SET_COMMENT_ID',
  SET_SHOW_DELETE_MODAL = 'SET_SHOW_DELETE_MODAL',
  SET_SENT_DELETE = 'SET_SENT_DELETE',
  SET_EDIT_COMMENT = 'SET_EDIT_COMMENT',
  SET_HAS_UPDATE = 'SET_HAS_UPDATE',
  SET_REMOVING_ID = 'SET_REMOVING_ID',
  SET_VISIBLE_RANGE = 'SET_VISIBLE_RANGE',
};

export type ICommentsActionReducer = |
{ type: EnumCommentsActionsReducer.SET_COPYING_ID, payload: string | null } |
{ type: EnumCommentsActionsReducer.SET_COMMENT_ID, payload: string | null } |
{ type: EnumCommentsActionsReducer.SET_SHOW_DELETE_MODAL, payload: boolean } |
{ type: EnumCommentsActionsReducer.SET_SENT_DELETE, payload: boolean } |
{ type: EnumCommentsActionsReducer.SET_EDIT_COMMENT, payload: IComment | null } |
{ type: EnumCommentsActionsReducer.SET_HAS_UPDATE, payload: boolean } |
{ type: EnumCommentsActionsReducer.SET_REMOVING_ID, payload: string[] | null } |
{ type: EnumCommentsActionsReducer.SET_VISIBLE_RANGE, payload: IVisibleRange };
