import styled from "styled-components";

interface IWrapper {
  first?: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  width: 100%;
  height: auto;
  padding-top: 4px;
`;

interface IContainer {
  active?: boolean;
  isupdate?: boolean;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding: 6px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.surface.surface};
  cursor: pointer;

  .leftContainer {}

  .rightContainer {
    display: flex;
    flex-direction: row;
    margin-left: 10px;

    .rightTextContainer {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .rightDescription {
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: ${({ theme }) => theme.colors.surface.onSurface};
      }
    
      .rightName {
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
      }
    
      .rightDate {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
      }
    }
    
    .rightActionButtons {
      min-width: 56px;
      width: 56px;
      display: flex;
      justify-content: flex-end;

      .rightActionButtonsRead {
        display: none;
      }

      .badgeNewNotification {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.notification.notification};
        margin-top: 7px;
      }
    }
  }

  :hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};

    .rightContainer {
      
      .rightActionButtons {

        .rightActionButtonsRead {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          background-color: ${({ theme }) => theme.colors.surface.surface};

          .iconReadNotification {
            color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
          }

          :hover {
            background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
          }
        }

        .badgeNewNotification {
          display: none;
        }
      }
    }
  }
`;

export const OwnerBadge = styled.span`
  height: 16px;
  width: auto;
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.secondary.secondary};
  margin-left: 6px;

  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.secondary.onSecondary};
`;
