import {
  ActivitiesActionKeys,
  ActivitiesActionUnion,
} from "./Activities.actions";
import { IActivitiesState } from "./interfaces/IActivitiesState";
import { arrayMove } from "@dnd-kit/sortable";
import {
  IDragAndDropDataFormat,
  ITopicAttachment,
  ITopicCommunicationType,
  ITopicDnDResponse,
  arrayTopicKeys,
  topicKeysResponseFormat,
} from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { IChangeStatus } from "Data/interfaces/Activities/IChangeStatus";
import { numberSort } from "../../Utils/Sort";
import {
  IFiltroOptions,
  uniqueSelectionKeys,
} from "Data/interfaces/Activities/IFilters";
import { v4 as uuid } from 'uuid';
import dayjs from "dayjs";
import { IStage } from "Data/interfaces/Obra/IObraStages";
import { isObjectEmpty } from "Utils/ObjectUtils";
import {
  IComment,
  ICommentActive,
  ICommentComponent,
} from "Data/interfaces/Activities/IComments";

const initialState: IActivitiesState = {
  listActivities: undefined,
  topicStatusOptions: [],
  topicStagesOptions: [],
  editStage: false,
  stagesFeatureValidate: undefined,
  topicPointsOptions: [],
  topicTypesOptions: [],
  constructionSiteDisciplinesOptions: [],
  topicCommunicationTypesOptions: [],
  usersOptions: [],
  savedActivitieTenantOption: undefined,
  isLoading: false,
  isLoadingCreate: false,
  isLoadingUpdate: false,
  isLoadingDelete: false,
  isChangeStatusLoading: false,
  initialStatusContainer: undefined,
  externalGlobalLoading: false,
  endStatus: undefined,
  lastModifiedStatus: 'atualizado',
  lastModifiedDate: undefined,
  updateStatus: null,
  topicSignalRHasUpdate: null,
  getOld: false,
  newActivityGuid: undefined,
  shareLink: undefined,
  isLoadingShareLink: false,
  filters: {},
  attachmentsUploading: undefined,
  attachmentsForUpload: {
    attachments: {},
    attachmentsIds: [],
  },
  attachmentsForCreate: [],
  isLoadingAttachmentUploading: false,
  uploadAttachementCancelToken: null,
  isLoadingAttachmentDeleting: false,
  attachmentPreviewOn: false,
  notShowError: false,
  isLoadingComents: false,
  isActionLoadingComent: false,
  activeComment: {
    lastReadAt: undefined,
    comments: [],
  },
  commentSignalRHasUpdate: null,
  commentSignalRHasDelete: null,
  shareLinkComment: undefined,
  isLoadingShareLinkComment: false,
};

const ActivitiesReducer = (
  state = initialState,
  action: ActivitiesActionUnion
): IActivitiesState => {
  switch (action.type) {
    case ActivitiesActionKeys.LIST_ACTIVITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        lastModifiedStatus: 'atualizando',
        lastModifiedDate: undefined,
      };
    case ActivitiesActionKeys.LIST_ACTIVITIES_SUCCESS:
      const newActivitiesList: IDragAndDropDataFormat = {
        activities: {},
        columns: {},
        columnOrder: [],
      };
      const csId = action.payload.csId;
      const activitiesListResponse = action.payload.listActivities;
      const statusOptions = action.payload.TopicStatus;
      const topicStagesOptions = action.payload.TopicStages;
      const topicPointsOptions = action.payload.TopicPoints;
      const topicTypesOptions = action.payload.TopicTypes;
      const constructionSiteDisciplinesOptions = action.payload.ConstructionSiteDisciplines;
      const topicCommunicationTypesOptions = action.payload.TopicCommunicationTypes;
      const usersOptions = action.payload.Users;

      if (statusOptions) {
        const columnsReorder = statusOptions.sort((a, b) => numberSort(Number(a.Order), Number(b.Order)));
        const columnOrder = [];
        for (const column of columnsReorder) {
          newActivitiesList.columns[`column-${column.TopicStatusId}`] = {
            ...column,
            taskIds: [],
          };
          columnOrder.push(`column-${column.TopicStatusId}`);
        }
        newActivitiesList.columnOrder = columnOrder;
      }

      if (newActivitiesList && activitiesListResponse) {
        for (const activity of activitiesListResponse) {
          const formatAttachment: ITopicAttachment[] = activity?.Attachments?.map(attachment => {
            return {
              ...attachment,
              File: {
                ...attachment.File,
                CreatorUser: usersOptions?.find(user => user.Id === attachment.File.CreatorUserFk) || null,
                CreationDate: dayjs(attachment.File.CreationDate),
              }
            }
          }) || [];

          newActivitiesList.activities[activity.Guid] = {
            Guid: activity.Guid,
            Identifier: activity.Identifier,
            Title: activity.Title,
            Description: activity.Description,
            Priority: activity.Priority,
            Visibility: activity.Visibility,
            DueDate: activity.DueDate,
            CreationDate: activity.CreationDate,
            ModifiedDate: activity.ModifiedDate,
            Stage: topicStagesOptions?.find(status => status.TopicStageId === activity?.StageFk) || null,
            CommunicationTypes: topicCommunicationTypesOptions?.find(comm => comm.TopicCommunicationTypeId === activity?.CommunicationTypeFk) || null,
            TopicPoints: topicPointsOptions?.filter(point => activity.TopicPointsFk?.includes(point.TopicPointId)) || [],
            ConstructionSiteDisciplines: constructionSiteDisciplinesOptions?.filter(discipline => activity.ConstructionSiteDisciplinesFk?.includes(discipline.ConstructionSiteDisciplinesId)),
            TopicAssignedUsers: usersOptions?.filter(user => activity.TopicAssignedUsersFk?.includes(user.Id || '')) || [],
            ConstructionSiteFk: activity.ConstructionSiteFk || csId,
            CreationAuthor: usersOptions?.find(user => user.Id === activity?.CreationAuthorFk) || null,
            Status: statusOptions.find(status => status.TopicStatusId === activity.StatusFk) || statusOptions[0],
            Type: topicTypesOptions.find(type => type.TopicTypeId === activity.TypeFk) || topicTypesOptions[0],
            Attachments: formatAttachment,
          };

          newActivitiesList.columns[`column-${activity.StatusFk || statusOptions.find(status => status.Order === 1)?.TopicStatusId}`].taskIds.push(activity.Guid);
        }
      }

      let newFiltersState = state.filters;
      const filtersData = action.payload.filtersData;
      if (filtersData.length > 0) {

        for (const filterData of filtersData) {
          const newFilterId = uuid();
          const topicId = topicKeysResponseFormat[filterData.Field] as keyof ITopicDnDResponse;

          const operation = filterData.ExtraData['operation'];
          const properties = filterData.ExtraData['properties'];
          const description = filterData.ExtraData['description'];

          const value = (uniqueSelectionKeys.includes(topicId) && filterData.Values.length === 1)
            ? filterData.Values[0]
            : filterData.Values;

          newFiltersState[newFilterId] = {
            topicId,
            operation,
            value,
            properties,
            description,
          }
        }
      }

      return {
        ...state,
        isLoading: false,
        lastModifiedStatus: 'atualizado',
        lastModifiedDate: new Date(),
        listActivities: { ...newActivitiesList },
        topicStatusOptions: [...statusOptions],
        topicStagesOptions: [...topicStagesOptions],
        stagesFeatureValidate: action.payload.stagesFeatureValidate,
        topicPointsOptions: [...topicPointsOptions],
        topicTypesOptions: [...topicTypesOptions],
        constructionSiteDisciplinesOptions: [...constructionSiteDisciplinesOptions],
        topicCommunicationTypesOptions: [...topicCommunicationTypesOptions],
        usersOptions: [...usersOptions],
        filters: { ...newFiltersState },
      };
    case ActivitiesActionKeys.LIST_ACTIVITIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        lastModifiedStatus: 'error',
        lastModifiedDate: undefined,
        listActivities: undefined,
        error: action.payload,
      };

    case ActivitiesActionKeys.ACTIVITIES_CREATE_STAGES_SUCCESS:
      const stagesCreate = state.topicStagesOptions;
      if (action.payload.stage) {
        if (action.payload.stage.IsDefault) {
          const currentDefaultIndex = stagesCreate.findIndex(stage => stage.IsDefault);

          if (currentDefaultIndex !== -1) {
            stagesCreate[currentDefaultIndex].IsDefault = false;
          }
        }
        stagesCreate.push(action.payload.stage);
      }
      return {
        ...state,
        topicStagesOptions: [...stagesCreate],
        editStage: true,
      }
    case ActivitiesActionKeys.ACTIVITIES_UPDATE_STAGES_SUCCESS:
      const listActivitiesStagesState = state.listActivities || {} as IDragAndDropDataFormat;
      const stagesUpdate = state.topicStagesOptions;
      if (action.payload.stage) {
        if (action.payload.stage.IsDefault) {
          const currentDefaultIndex = stagesUpdate.findIndex(stage => stage.IsDefault);
          if (
            currentDefaultIndex !== -1 &&
            stagesUpdate[currentDefaultIndex].TopicStageId !== action.payload.stage.TopicStageId
          ) {
            stagesUpdate[currentDefaultIndex].IsDefault = false;
          }
        }

        const index = stagesUpdate.findIndex(stage => {
          return stage.TopicStageId === action.payload.stage.TopicStageId;
        });
        if (index !== -1) {
          stagesUpdate[index] = action.payload.stage;

          if (
            listActivitiesStagesState &&
            !isObjectEmpty(listActivitiesStagesState.activities)
          ) {
            for (const Guid of Object.keys(listActivitiesStagesState.activities)) {
              if (
                listActivitiesStagesState.activities[Guid]?.Stage &&
                listActivitiesStagesState.activities[Guid].Stage?.TopicStageId === action.payload.stage.TopicStageId
              ) {
                listActivitiesStagesState.activities[Guid].Stage = action.payload.stage;
              }
            }
          }
        }
      }
      return {
        ...state,
        listActivities: { ...listActivitiesStagesState },
        topicStagesOptions: [...stagesUpdate],
        editStage: true,
      }
    case ActivitiesActionKeys.ACTIVITIES_SET_EDIT_STAGES:
      return {
        ...state,
        editStage: action.payload,
      }

    case ActivitiesActionKeys.LIST_ACTIVITIES_UPDATE_REQUEST:
      return {
        ...state,
        endStatus: undefined,
        isLoading: false,
      };
    case ActivitiesActionKeys.LIST_ACTIVITIES_UPDATE_SUCCESS:
      const listActivitiesState = state.listActivities;
      const dataContainer = action.payload;
      const initialStatusContainer = state.initialStatusContainer;
      let newEndStatus = state.endStatus;
      let newColum1;
      let newColum2;
      let newActiveStatus = {} as ITopicDnDResponse;

      if (listActivitiesState) {
        const {
          provider,
          active,
          activeContainer,
          over,
          overContainer,
          delta,
        } = dataContainer;

        const { id } = active;
        const { id: overId } = over;

        if (activeContainer && overContainer) {
          const start = listActivitiesState.columns[activeContainer];
          const finish = listActivitiesState.columns[overContainer];

          if (provider === 'end' && activeContainer === overContainer) {
            if (start.TopicStatusId === finish.TopicStatusId) {
              const newTaskIds = Array.from(start.taskIds);
              const startIndex = newTaskIds.indexOf(id.toString());
              const finishIndex = newTaskIds.indexOf(overId.toString());

              listActivitiesState.columns[`column-${start.TopicStatusId}`] = {
                ...start,
                taskIds: arrayMove(
                  newTaskIds,
                  startIndex,
                  finishIndex
                )
              };

              newActiveStatus = listActivitiesState.activities[id];
              newActiveStatus.Status = {
                TopicStatusId: start.TopicStatusId,
                Name: start.Name,
                Color: start.Color,
                Order: start.Order,
                TenantFk: start.TenantFk,
              };

              if (initialStatusContainer && (initialStatusContainer.initialContainer !== finish.TopicStatusId)) {
                const statusChange: IChangeStatus = {
                  Guid: id.toString(),
                  updateData: [{
                    path: 'StatusFk',
                    op: 'replace',
                    value: finish.TopicStatusId,
                  }],
                  getEndContainer: {
                    endIndex: finishIndex,
                    endContainer: finish.TopicStatusId,
                  }
                }
                newEndStatus = statusChange;
              }
            }
          }

          if (provider === 'over' && activeContainer !== overContainer) {
            const startTaskIds = Array.from(start.taskIds);
            const finishTaskIds = Array.from(finish.taskIds);

            const activeIndex = startTaskIds.indexOf(id.toString());
            const overIndex = finishTaskIds.indexOf(overId.toString());

            let newIndex;
            if (listActivitiesState.columns.hasOwnProperty(overId)) {
              // We're at the root droppable of a container
              newIndex = finishTaskIds.length + 1;
            } else {
              const isBelowLastItem =
                over &&
                overIndex === finishTaskIds.length - 1 &&
                delta?.x > over.rect.top + over.rect.height;

              const modifier = isBelowLastItem ? 1 : 0;

              newIndex = overIndex >= 0 ? overIndex + modifier : finishTaskIds.length + 1;
            }

            newColum1 = listActivitiesState.columns[`column-${start.TopicStatusId}`] = {
              ...start,
              taskIds: [
                ...startTaskIds.filter(tasks => tasks !== id),
              ],
            };
            newColum2 = listActivitiesState.columns[`column-${finish.TopicStatusId}`] = {
              ...finish,
              taskIds: [
                ...finishTaskIds.slice(0, newIndex),
                startTaskIds[activeIndex],
                ...finishTaskIds.slice(newIndex, finishTaskIds.length),
              ],
            };
          }
        }
      }
      return {
        ...state,
        ...newColum1,
        ...newColum2,
        ...newActiveStatus,
        endStatus: newEndStatus,
        isLoading: false,
      };
    case ActivitiesActionKeys.LIST_ACTIVITIES_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case ActivitiesActionKeys.CREATE_ACTIVITY_REQUEST:
      return {
        ...state,
        isLoadingCreate: true,
        newActivityGuid: undefined,
      };
    case ActivitiesActionKeys.CREATE_ACTIVITY_SUCCESS:
      const newCreateActivitiesList = state.listActivities || {} as IDragAndDropDataFormat;
      const activityCreateResponse = action.payload.activity;
      const newTopicStagesOptionsCreate = action.payload.Stage;
      const newTopicPointsOptionsCreate = action.payload.Points;
      const newTopicCommunicationOptionsCreate = action.payload.CommunicationType;

      const topicStatusOptionsCreate = state.topicStatusOptions;
      const topicStagesOptionsCreate = newTopicStagesOptionsCreate
        ? [...state.topicStagesOptions, newTopicStagesOptionsCreate]
        : [...state.topicStagesOptions];
      const topicPointsOptionsCreate = newTopicPointsOptionsCreate
        ? [...state.topicPointsOptions, ...newTopicPointsOptionsCreate]
        : [...state.topicPointsOptions];
      const topicCommunicationOptionsCreate = newTopicCommunicationOptionsCreate
        ? [...state.topicCommunicationTypesOptions, newTopicCommunicationOptionsCreate]
        : [...state.topicCommunicationTypesOptions];
      const topicTypesOptionsCreate = state.topicTypesOptions;
      const constructionSiteDisciplinesOptionsCreate = state.constructionSiteDisciplinesOptions;
      const usersOptionsCreate = state.usersOptions;

      if (newCreateActivitiesList && activityCreateResponse) {
        const formatAttachment: ITopicAttachment[] = activityCreateResponse?.Attachments?.map(attachment => {
          return {
            ...attachment,
            File: {
              ...attachment.File,
              CreatorUser: usersOptionsCreate?.find(user => user.Id === attachment.File.CreatorUserFk) || null,
              CreationDate: dayjs(attachment.File.CreationDate),
            }
          }
        }) || [];

        newCreateActivitiesList.activities[activityCreateResponse.Guid] = {
          Guid: activityCreateResponse.Guid,
          Identifier: activityCreateResponse.Identifier,
          Title: activityCreateResponse.Title,
          Description: activityCreateResponse.Description,
          Priority: activityCreateResponse.Priority,
          Visibility: activityCreateResponse.Visibility,
          DueDate: activityCreateResponse.DueDate,
          CreationDate: activityCreateResponse.CreationDate,
          ModifiedDate: activityCreateResponse.ModifiedDate,
          Stage: topicStagesOptionsCreate?.find(status => status.TopicStageId === activityCreateResponse?.StageFk) || null,
          CommunicationTypes: topicCommunicationOptionsCreate?.find(comm => comm.TopicCommunicationTypeId === activityCreateResponse?.CommunicationTypeFk) || null,
          TopicPoints: topicPointsOptionsCreate?.filter(point => activityCreateResponse.TopicPointsFk?.includes(point.TopicPointId)),
          ConstructionSiteDisciplines: constructionSiteDisciplinesOptionsCreate?.filter(discipline => activityCreateResponse.ConstructionSiteDisciplinesFk?.includes(discipline.ConstructionSiteDisciplinesId)),
          TopicAssignedUsers: usersOptionsCreate?.filter(user => activityCreateResponse.TopicAssignedUsersFk?.includes(user.Id || '')),
          ConstructionSiteFk: activityCreateResponse.ConstructionSiteFk,
          CreationAuthor: usersOptionsCreate?.find(user => user.Id === activityCreateResponse?.CreationAuthorFk) || null,
          Status: topicStatusOptionsCreate.find(status => status.TopicStatusId === activityCreateResponse.StatusFk) || topicStatusOptionsCreate[0],
          Type: topicTypesOptionsCreate.find(type => type.TopicTypeId === activityCreateResponse.TypeFk) || topicTypesOptionsCreate[0],
          Attachments: formatAttachment,
        };
        newCreateActivitiesList.columns[`column-${activityCreateResponse.StatusFk}`].taskIds.unshift(activityCreateResponse.Guid);
      }

      return {
        ...state,
        isLoadingCreate: false,
        listActivities: { ...newCreateActivitiesList },
        topicStagesOptions: [...topicStagesOptionsCreate],
        topicPointsOptions: [...topicPointsOptionsCreate],
        topicCommunicationTypesOptions: [...topicCommunicationOptionsCreate],
        attachmentsForCreate: [],
        newActivityGuid: activityCreateResponse.Guid,
      };
    case ActivitiesActionKeys.CREATE_ACTIVITY_FAILURE:
      return {
        ...state,
        isLoadingCreate: false,
        newActivityGuid: undefined,
        attachmentsForCreate: [],
        error: action.payload,
      };

    case ActivitiesActionKeys.CHANGE_ACTIVITY_STATUS_REQUEST:
      return {
        ...state,
        isChangeStatusLoading: true,
      };
    case ActivitiesActionKeys.CHANGE_ACTIVITY_STATUS_SUCCESS:
      return {
        ...state,
        endStatus: undefined,
        isChangeStatusLoading: false,
      };
    case ActivitiesActionKeys.CHANGE_ACTIVITY_STATUS_FAILURE:
      const newListStatusActivityError = state.listActivities || {} as IDragAndDropDataFormat;
      const initialStatusContainerError = state.initialStatusContainer;
      const changeStatusErrorResponse = action.payload.getEndContainer;
      let newColum1Error;
      let newColum2Error;
      let newActiveStatusError = {} as ITopicDnDResponse;

      if (newListStatusActivityError && initialStatusContainerError && changeStatusErrorResponse) {
        const start = newListStatusActivityError.columns[`column-${initialStatusContainerError.initialContainer}`];
        const finish = newListStatusActivityError.columns[`column-${changeStatusErrorResponse.endContainer}`];

        const startTaskIds = Array.from(start.taskIds);
        const finishTaskIds = Array.from(finish.taskIds);

        newActiveStatusError = newListStatusActivityError.activities[initialStatusContainerError.currentId];
        newActiveStatusError.Status = {
          TopicStatusId: start.TopicStatusId,
          Name: start.Name,
          Color: start.Color,
          Order: start.Order,
          TenantFk: start.TenantFk,
        };

        newColum1Error = newListStatusActivityError.columns[`column-${finish.TopicStatusId}`] = {
          ...finish,
          taskIds: [
            ...finishTaskIds.filter(tasks => tasks !== initialStatusContainerError.currentId),
          ],
        };
        newColum2Error = newListStatusActivityError.columns[`column-${start.TopicStatusId}`] = {
          ...start,
          taskIds: [
            ...startTaskIds.slice(0, initialStatusContainerError.initialIndex),
            initialStatusContainerError.currentId.toString(),
            ...startTaskIds.slice(initialStatusContainerError.initialIndex, startTaskIds.length),
          ],
        };
      }

      return {
        ...state,
        ...newColum1Error,
        ...newColum2Error,
        ...newActiveStatusError,
        endStatus: undefined,
        isChangeStatusLoading: false,
        error: action.payload.error,
        getOld: true,
      };

    case ActivitiesActionKeys.UPDATE_ACTIVITY_REQUEST:
      return {
        ...state,
        isLoadingUpdate: true,
        updateStatus: 'atualizando',
      };
    case ActivitiesActionKeys.UPDATE_ACTIVITY_SUCCESS:
      const newActivitiesUpdateList = state.listActivities || {} as IDragAndDropDataFormat;
      const activityUpdateResponse = action.payload;
      const newUpdateTopicStagesOptionsCreate = action.payload.Stage;
      const newUpdateTopicPointsOptionsCreate = action.payload.Points;
      const newUpdateTopicCommunicationOptionsCreate = action.payload.CommunicationType;

      const updateStatusOptions = state.topicStatusOptions;
      const updateTopicStagesOptions = newUpdateTopicStagesOptionsCreate
        ? [...state.topicStagesOptions, newUpdateTopicStagesOptionsCreate]
        : [...state.topicStagesOptions];
      const updateTopicPointsOptions = newUpdateTopicPointsOptionsCreate
        ? [...state.topicPointsOptions, ...newUpdateTopicPointsOptionsCreate]
        : [...state.topicPointsOptions];
      const updateTopicCommunicationTypesOptions = newUpdateTopicCommunicationOptionsCreate
        ? [...state.topicCommunicationTypesOptions, newUpdateTopicCommunicationOptionsCreate]
        : [...state.topicCommunicationTypesOptions];
      const updateTopicTypesOptions = state.topicTypesOptions;
      const updateConstructionSiteDisciplinesOptions = state.constructionSiteDisciplinesOptions;
      const updateUsersOptions = state.usersOptions;


      if (newActivitiesUpdateList && activityUpdateResponse) {
        activityUpdateResponse.updateData.forEach(data => {
          const index = data.path;
          const value = data.value;

          switch (index) {
            case 'Title':
              if (typeof value === 'string') {
                newActivitiesUpdateList.activities[activityUpdateResponse.Guid].Title = value;
              }
              break;

            case 'Description':
              let newDescriptionUpdate: string | null = null;
              if (typeof value === 'string') {
                newDescriptionUpdate = value;
              }
              newActivitiesUpdateList.activities[activityUpdateResponse.Guid].Description = newDescriptionUpdate;
              break;

            case 'Priority':
              let newPriorityUpdate: number | null = null;
              if (typeof value === 'number') {
                newPriorityUpdate = value;
              }
              newActivitiesUpdateList.activities[activityUpdateResponse.Guid][index] = newPriorityUpdate;
              break;

            case 'Visibility':
              if (typeof value === 'number') {
                newActivitiesUpdateList.activities[activityUpdateResponse.Guid][index] = value;
              }
              break;

            case 'StageFk':
            case 'NewStage':
              if (typeof value === 'number' || typeof value === 'string' || !value) {
                let newStageUpdate: IStage | null = null;
                if (newUpdateTopicStagesOptionsCreate) {
                  newStageUpdate = newUpdateTopicStagesOptionsCreate;
                } else {
                  newStageUpdate = updateTopicStagesOptions?.find(status => status.TopicStageId === value) || null;
                }
                newActivitiesUpdateList.activities[activityUpdateResponse.Guid].Stage = newStageUpdate;
              }
              break;

            case 'CommunicationTypeFk':
            case 'NewCommunicationType':
              if (typeof value === 'number' || typeof value === 'string' || !value) {
                let newCommunicationUpdate: ITopicCommunicationType | null = null;
                if (newUpdateTopicCommunicationOptionsCreate) {
                  newCommunicationUpdate = newUpdateTopicCommunicationOptionsCreate;
                } else {
                  newCommunicationUpdate = updateTopicCommunicationTypesOptions.find(communication => {
                    return communication?.TopicCommunicationTypeId === value;
                  }) || null;
                }
                newActivitiesUpdateList.activities[activityUpdateResponse.Guid].CommunicationTypes = newCommunicationUpdate;
              }
              break;

            case 'PointsFk':
              if (Array.isArray(value) && !value.some((v: string | number) => typeof v !== 'number') && state.topicPointsOptions.length > 0) {
                const newValues = value as number[];
                newActivitiesUpdateList.activities[activityUpdateResponse.Guid].TopicPoints = state.topicPointsOptions.filter(point => newValues?.includes(point.TopicPointId));
              }
              break;

            case 'NewPoints':
              if (Array.isArray(value) && newUpdateTopicPointsOptionsCreate && newUpdateTopicPointsOptionsCreate?.length > 0) {
                newActivitiesUpdateList.activities[activityUpdateResponse.Guid].TopicPoints = [
                  ...newActivitiesUpdateList.activities[activityUpdateResponse.Guid].TopicPoints,
                  ...newUpdateTopicPointsOptionsCreate,
                ]
              }
              break;

            case 'DueDate':
              let newDueDateUpdate: Date | null = null;
              if (typeof value === 'string') {
                newDueDateUpdate = value as any;
              }
              newActivitiesUpdateList.activities[activityUpdateResponse.Guid].DueDate = newDueDateUpdate;
              break;

            case 'AssignedUsersFk':
              if (Array.isArray(value) || !value) {
                const newValue = value as string[] || [];
                newActivitiesUpdateList.activities[activityUpdateResponse.Guid].TopicAssignedUsers = updateUsersOptions?.filter(user => newValue?.includes(user.Id || ''));
              }
              break;

            case 'ConstructionSiteDisciplinesFk':
              if (Array.isArray(value) && value.length > 0 && !value.some((v: string | number) => typeof v !== 'number')) {
                const newValue = value as number[];
                newActivitiesUpdateList.activities[activityUpdateResponse.Guid].ConstructionSiteDisciplines = updateConstructionSiteDisciplinesOptions?.filter(discipline => newValue?.includes(discipline.ConstructionSiteDisciplinesId));
              }
              break;

            case 'TypeFk':
              if (typeof value === 'number') {
                newActivitiesUpdateList.activities[activityUpdateResponse.Guid].Type = updateTopicTypesOptions.find(type => type.TopicTypeId === value) || updateTopicTypesOptions[0];
              }
              break;

            case 'StatusFk':
              if (typeof value === 'number') {
                const oldStatusId = newActivitiesUpdateList.activities[activityUpdateResponse.Guid].Status.TopicStatusId;
                if (oldStatusId !== value) {
                  const newTaskIds = Array.from(newActivitiesUpdateList.columns[`column-${oldStatusId}`].taskIds);
                  const startIndex = newTaskIds.indexOf(activityUpdateResponse.Guid);

                  newActivitiesUpdateList.columns[`column-${oldStatusId}`].taskIds.splice(startIndex, 1);
                  newActivitiesUpdateList.columns[`column-${value}`].taskIds.unshift(activityUpdateResponse.Guid);

                  newActivitiesUpdateList.activities[activityUpdateResponse.Guid].Status = updateStatusOptions.find(status => status.TopicStatusId === value) || updateStatusOptions[0];
                }
              }
              break;

            default:
              return;
          }
        });
      }
      return {
        ...state,
        isLoadingUpdate: false,
        listActivities: { ...newActivitiesUpdateList },
        topicStagesOptions: [...updateTopicStagesOptions],
        topicPointsOptions: [...updateTopicPointsOptions],
        topicCommunicationTypesOptions: [...updateTopicCommunicationTypesOptions],
        error: undefined,
        updateStatus: 'atualizado',
      };
    case ActivitiesActionKeys.UPDATE_ACTIVITY_FAILURE:
      return {
        ...state,
        isLoadingUpdate: false,
        error: action.payload,
        getOld: true,
        updateStatus: 'error',
      };

    case ActivitiesActionKeys.DELETE_ACTIVITY_REQUEST:
      return {
        ...state,
        isLoadingDelete: true,
      };
    case ActivitiesActionKeys.DELETE_ACTIVITY_SUCCESS:
      const newActivitiesDeleteList = state.listActivities || {} as IDragAndDropDataFormat;
      const activityDeleteId = action.payload;

      if (newActivitiesDeleteList && activityDeleteId) {
        const columnId = newActivitiesDeleteList.activities[activityDeleteId].Status.TopicStatusId;
        const columnActivitieDelete = newActivitiesDeleteList.columns[`column-${columnId}`];

        newActivitiesDeleteList.columns[`column-${columnId}`] = {
          ...columnActivitieDelete,
          taskIds: [
            ...columnActivitieDelete.taskIds.filter(tasks => tasks !== activityDeleteId),
          ],
        };

        delete newActivitiesDeleteList.activities[activityDeleteId];
      }
      return {
        ...state,
        listActivities: { ...newActivitiesDeleteList },
        isLoadingDelete: false,
      };
    case ActivitiesActionKeys.DELETE_ACTIVITY_FAILURE:
      return {
        ...state,
        isLoadingDelete: false,
        error: action.payload,
      };

    case ActivitiesActionKeys.INITIAL_CHANGE_ACTIVITY_STATUS:
      return {
        ...state,
        initialStatusContainer: action.payload,
      };

    case ActivitiesActionKeys.EXTERNAL_GLOBAL_LOADING:
      return {
        ...state,
        externalGlobalLoading: action.payload,
      };

    case ActivitiesActionKeys.CLEAR_GET_OLD:
      return {
        ...state,
        error: undefined,
        getOld: false,
      };

    case ActivitiesActionKeys.CHANGE_UPDATE_STATUS:
      return {
        ...state,
        updateStatus: action.payload,
      };

    case ActivitiesActionKeys.TOPIC_SIGNALR_HAS_UPDATE:
      return {
        ...state,
        topicSignalRHasUpdate: action.payload,
      };

    case ActivitiesActionKeys.HAS_REMOVED_OPTIONS:
      const removedTopicStagesOptionsId = action.payload.StagesId;
      const removedTopicPointsOptionsId = action.payload.PointsId;
      const removedTopicCommunicationOptionsId = action.payload.CommunicationTypesId;
      let currentDontRemovedTopicStagesOptions = state.topicStagesOptions;
      let currentDontRemovedTopicPointsOptions = state.topicPointsOptions;
      let currentDontRemovedTopicCommunicationOptions = state.topicCommunicationTypesOptions;

      if (removedTopicStagesOptionsId.length > 0) {
        currentDontRemovedTopicStagesOptions = currentDontRemovedTopicStagesOptions.filter(opt => {
          return !removedTopicStagesOptionsId.includes(opt.TopicStageId);
        })
      }
      if (removedTopicPointsOptionsId.length > 0) {
        currentDontRemovedTopicPointsOptions = currentDontRemovedTopicPointsOptions.filter(opt => {
          return !removedTopicPointsOptionsId.includes(opt.TopicPointId);
        })
      }
      if (removedTopicCommunicationOptionsId.length > 0) {
        currentDontRemovedTopicCommunicationOptions = currentDontRemovedTopicCommunicationOptions.filter(opt => {
          return !removedTopicCommunicationOptionsId.includes(opt.TopicCommunicationTypeId);
        })
      }
      return {
        ...state,
        topicStagesOptions: [...currentDontRemovedTopicStagesOptions],
        topicPointsOptions: [...currentDontRemovedTopicPointsOptions],
        topicCommunicationTypesOptions: [...currentDontRemovedTopicCommunicationOptions],
      };

    case ActivitiesActionKeys.POPULATED_ACTIVITY_FILTERS:
      return {
        ...state,
        filters: { ...action.payload },
      };
    case ActivitiesActionKeys.ADD_ACTIVITY_FILTER:
      let newAddfilters = state.filters;

      const newFilterId = action.payload;

      const newAddCurrentArrayTopicKeys = arrayTopicKeys.filter(key => {
        return !Object.values(newAddfilters).some(filter => filter.topicId === key);
      })
      const addTopicId = newAddCurrentArrayTopicKeys.pop();

      if (newFilterId) {
        newAddfilters[newFilterId] = {
          topicId: addTopicId,
        } as IFiltroOptions<ITopicDnDResponse>;
      }

      return {
        ...state,
        filters: { ...newAddfilters },
      };
    case ActivitiesActionKeys.UPDATE_ACTIVITY_FILTER:
      let newUpdatefilters = state.filters;
      const updateData = action.payload;

      const {
        filterId,
        topicId,
        operation,
        value,
        properties,
        description,
      } = updateData.values;

      switch (updateData.op) {
        case "id":
          if (filterId && topicId) {
            newUpdatefilters[filterId] = {
              ...newUpdatefilters[filterId],
              topicId,
              operation: 'equal',
              value: undefined,
              properties: undefined,
              description: undefined,
            };
          }
          break;
        case "op":
          if (filterId && operation) {
            newUpdatefilters[filterId].operation = operation;
          }
          break;
        case "vl":
          if (filterId) {
            newUpdatefilters[filterId].value = value;
            newUpdatefilters[filterId].properties = properties;
            newUpdatefilters[filterId].description = description;
          }
          break;
      }

      return {
        ...state,
        filters: { ...newUpdatefilters },
      };
    case ActivitiesActionKeys.DELETE_ACTIVITY_FILTER:
      let newDeletefilters = state.filters;
      const deleteFilterId = action.payload;

      if (deleteFilterId) {
        delete newDeletefilters[deleteFilterId];
      }

      return {
        ...state,
        filters: { ...newDeletefilters },
      };
    case ActivitiesActionKeys.CLEAR_ALL_ACTIVITY_FILTERS:
      return {
        ...state,
        filters: {},
      };

    case ActivitiesActionKeys.SHARE_LINK_ACTIVITY_REQUEST:
      return {
        ...state,
        isLoadingShareLink: true,
      };
    case ActivitiesActionKeys.SHARE_LINK_ACTIVITY_SUCCESS:
      return {
        ...state,
        shareLink: action.payload,
        isLoadingShareLink: false,
      };
    case ActivitiesActionKeys.SHARE_LINK_ACTIVITY_FAILURE:
      return {
        ...state,
        isLoadingShareLink: false,
        error: action.payload,
      };
    case ActivitiesActionKeys.SHARE_LINK_ACTIVITY_CLEAR:
      return {
        ...state,
        shareLink: undefined,
      };

    case ActivitiesActionKeys.ADD_ATTACHMENTS_FOR_UPLOAD:
      let addAttachmentsPopulated = state.attachmentsForUpload;
      const filesToAttachment = action.payload;

      if (filesToAttachment.length > 0) {
        for (const file of filesToAttachment) {
          const attachmentId = uuid();
          addAttachmentsPopulated.attachments[attachmentId] = {
            id: attachmentId,
            topicId: file.topicId,
            constructionSiteId: file.constructionSiteId,
            tenantId: file.tenantId,
            file: file.file,
            isUploading: false,
            isError: false,
            isCompleted: false,

            userInfoTracking: file.userInfoTracking,
            tenantTracking: file.tenantTracking,
          };
          addAttachmentsPopulated.attachmentsIds.push(attachmentId);
        }
      }

      return {
        ...state,
        attachmentsForUpload: { ...addAttachmentsPopulated },
      };
    case ActivitiesActionKeys.REMOVE_ATTACHMENT_ACTIVITY:
      let removeAttachmentsPopulated = state.attachmentsForUpload;
      let removeAttachmentUploading = state.attachmentsUploading;
      const removeAttachmentId = action.payload;
      const removeCancelToken = state.uploadAttachementCancelToken;

      if (removeAttachmentId) {
        if (removeAttachmentsPopulated.attachments.hasOwnProperty(removeAttachmentId)) {
          const indexForRemoveIds = removeAttachmentsPopulated.attachmentsIds.findIndex(id => {
            return id === removeAttachmentId
          });

          if (indexForRemoveIds !== -1) {
            removeAttachmentsPopulated.attachmentsIds.splice(indexForRemoveIds, 1);
          }

          if (
            removeAttachmentsPopulated.attachments[removeAttachmentId].isUploading &&
            removeAttachmentUploading &&
            removeCancelToken
          ) {
            removeCancelToken.cancel();
            removeAttachmentUploading = undefined;
          }

          delete removeAttachmentsPopulated.attachments[removeAttachmentId];
        }
      }

      return {
        ...state,
        attachmentsUploading: removeAttachmentUploading ? { ...removeAttachmentUploading } : undefined,
        attachmentsForUpload: { ...removeAttachmentsPopulated },
      };

    case ActivitiesActionKeys.UPLOAD_ATTACHMENT_REQUEST:
      let uploadAttachmentsRequest = state.attachmentsForUpload;
      let uploadAttachmentUploading = undefined;
      const uploadAttachment = action.payload;

      if (uploadAttachment) {
        uploadAttachmentsRequest.attachments[uploadAttachment.id].isUploading = true;
        uploadAttachmentUploading = {
          ...uploadAttachmentsRequest.attachments[uploadAttachment.id],
          sentSize: 0,
          percentage: 0,
        };
      }

      return {
        ...state,
        attachmentsUploading: uploadAttachmentUploading ? { ...uploadAttachmentUploading } : undefined,
        attachmentsForUpload: { ...uploadAttachmentsRequest },
        isLoadingAttachmentUploading: true,
        error: undefined,
      };
    case ActivitiesActionKeys.UPLOAD_ATTACHMENT_SUCCESS:
      const uploadAttachmentsSuccess = state.attachmentsForUpload;
      const successAttachmentUploadedId = action.payload.attachmentUploadedId;
      const successNewAttachment = action.payload.newAttachment;
      const newAttachmentsActivitiesList = state.listActivities || {} as IDragAndDropDataFormat;
      const usersOptionsAttachment = state.usersOptions;
      const newAttachmentsForCreate = state.attachmentsForCreate;

      if (newAttachmentsActivitiesList && successNewAttachment) {
        if (newAttachmentsActivitiesList.activities.hasOwnProperty(successNewAttachment.TopicFk)) {
          newAttachmentsActivitiesList.activities[successNewAttachment.TopicFk].Attachments.unshift({
            ...successNewAttachment,
            File: {
              ...successNewAttachment.File,
              CreatorUser: usersOptionsAttachment?.find(user => user.Id === successNewAttachment.File.CreatorUserFk) || null,
              CreationDate: dayjs(successNewAttachment.File.CreationDate),
            },
          });

        } else {
          newAttachmentsForCreate.push({
            ...successNewAttachment,
            File: {
              ...successNewAttachment.File,
              CreatorUser: usersOptionsAttachment?.find(user => user.Id === successNewAttachment.File.CreatorUserFk) || null,
              CreationDate: dayjs(successNewAttachment.File.CreationDate),
            },
          });
        }
      }

      if (uploadAttachmentsSuccess.attachments.hasOwnProperty(successAttachmentUploadedId)) {
        delete uploadAttachmentsSuccess.attachments[successAttachmentUploadedId];
      }

      return {
        ...state,
        attachmentsUploading: undefined,
        listActivities: { ...newAttachmentsActivitiesList },
        attachmentsForUpload: { ...uploadAttachmentsSuccess },
        attachmentsForCreate: [...newAttachmentsForCreate],
        isLoadingAttachmentUploading: false,
      };
    case ActivitiesActionKeys.UPLOAD_ATTACHMENT_FAILURE:
      let uploadAttachmentsError = state.attachmentsForUpload;
      const errorAttachmentUploadedId = action.payload.attachmentId;
      let failureAttachmentUploading = state.attachmentsUploading;

      if (errorAttachmentUploadedId) {
        if (uploadAttachmentsError.attachments.hasOwnProperty(errorAttachmentUploadedId)) {
          uploadAttachmentsError.attachments[errorAttachmentUploadedId].isUploading = false;
          uploadAttachmentsError.attachments[errorAttachmentUploadedId].isError = true;
        }
        if (errorAttachmentUploadedId === failureAttachmentUploading?.id) {
          failureAttachmentUploading = undefined;
        }
      }

      return {
        ...state,
        attachmentsUploading: failureAttachmentUploading ? { ...failureAttachmentUploading } : undefined,
        attachmentsForUpload: { ...uploadAttachmentsError },
        error: action.payload.error,
        isLoadingAttachmentUploading: false,
      };
    case ActivitiesActionKeys.UPLOAD_ATTACHMENT_CANCEL_ALL:
      const cancelToken = state.uploadAttachementCancelToken;

      if (cancelToken) {
        cancelToken.cancel();
      }

      return {
        ...state,
        attachmentsForUpload: {
          attachments: {},
          attachmentsIds: [],
        },
        attachmentsUploading: undefined,
        attachmentsForCreate: [],
        uploadAttachementCancelToken: null,
      };
    case ActivitiesActionKeys.UPDATE_UPLOAD_ATTACHMENT_PROGRESS:
      let progressAttachmentUploading = state.attachmentsUploading;

      if (progressAttachmentUploading) {
        if (
          progressAttachmentUploading?.id === action.payload.attachmentId &&
          state.attachmentsForUpload.attachments[action.payload.attachmentId].isUploading
        ) {
          progressAttachmentUploading.sentSize = action.payload.sentSize;
          progressAttachmentUploading.percentage = action.payload.percentage;
        } else {
          progressAttachmentUploading.sentSize = 0;
          progressAttachmentUploading.percentage = 0;
        }
      }
      return {
        ...state,
        attachmentsUploading: progressAttachmentUploading ? { ...progressAttachmentUploading } : undefined,
      };
    case ActivitiesActionKeys.ADD_UPLOAD_ATTACHMENT_CANCEL_TOKEN:
      return {
        ...state,
        uploadAttachementCancelToken: action.payload,
      };
    case ActivitiesActionKeys.CLEAR_UPLOAD_ATTACHMENT_CANCEL_TOKEN:
      return {
        ...state,
        uploadAttachementCancelToken: null,
      };
    case ActivitiesActionKeys.NOT_SHOW_ATTACHMENT_CANCEL_ERROR:
      return {
        ...state,
        notShowError: action.payload,
      };

    case ActivitiesActionKeys.DELETE_ATTACHMENT_FOR_CREATE_REQUEST:
      return {
        ...state,
        isLoadingAttachmentDeleting: true,
      };
    case ActivitiesActionKeys.DELETE_ATTACHMENT_FOR_CREATE_SUCCESS:
      const deletedAttachmentFileId = action.payload.fileId;
      const deletedAttachmentsForCreate = state.attachmentsForCreate;

      if (deletedAttachmentFileId) {
        const deletedIndex = deletedAttachmentsForCreate.findIndex(attachment => attachment.FileFk === deletedAttachmentFileId);

        if (deletedIndex !== -1) {
          deletedAttachmentsForCreate.splice(deletedIndex, 1);
        }
      }

      return {
        ...state,
        attachmentsForCreate: [...deletedAttachmentsForCreate],
        isLoadingAttachmentDeleting: false,
      };
    case ActivitiesActionKeys.DELETE_ATTACHMENT_FOR_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoadingAttachmentDeleting: false,
      };

    case ActivitiesActionKeys.DELETE_ATTACHMENT_REQUEST:
      return {
        ...state,
        isLoadingAttachmentDeleting: true,
      };
    case ActivitiesActionKeys.DELETE_ATTACHMENT_SUCCESS:
      const deletedAttachmentTopicAttachmentId = action.payload.topicAttachmentId;
      const deletedAttachmentTopicId = action.payload.topicFk;
      const deletedAttachmentsActivitiesList = state.listActivities || {} as IDragAndDropDataFormat;

      if (deletedAttachmentTopicAttachmentId) {
        if (
          deletedAttachmentTopicId &&
          deletedAttachmentsActivitiesList.activities.hasOwnProperty(deletedAttachmentTopicId)
        ) {
          const deletedIndex = deletedAttachmentsActivitiesList.activities[deletedAttachmentTopicId].Attachments.findIndex(attachment => attachment.TopicAttachmentId === deletedAttachmentTopicAttachmentId);

          if (deletedIndex !== -1) {
            deletedAttachmentsActivitiesList.activities[deletedAttachmentTopicId].Attachments.splice(deletedIndex, 1);
          }
        }
      }

      return {
        ...state,
        listActivities: { ...deletedAttachmentsActivitiesList },
        isLoadingAttachmentDeleting: false,
      };
    case ActivitiesActionKeys.DELETE_ATTACHMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoadingAttachmentDeleting: false,
      };

    case ActivitiesActionKeys.ATTACHMENT_PREVIEW_ON:
      return {
        ...state,
        attachmentPreviewOn: action.payload,
      };

    case ActivitiesActionKeys.LIST_COMMENTS_REQUEST:
      return {
        ...state,
        isLoadingComents: true,
        activeComment: {
          lastReadAt: undefined,
          comments: [],
        },
        commentSignalRHasUpdate: null,
        commentSignalRHasDelete: null,
      };
    case ActivitiesActionKeys.LIST_COMMENTS_SUCCESS:
      let commentContent: ICommentActive = {
        lastReadAt: undefined,
        comments: [],
      };
      if (action.payload) {
        commentContent.lastReadAt = dayjs(action.payload.lastReadAt);
        const data: ICommentComponent = {};

        const orderedComments = action.payload.comments.sort((a, b) => {
          return dayjs(a.CreationDate).isAfter(dayjs(b.CreationDate)) ? 1 : -1;
        })
        for (let i = 0; i < orderedComments.length; i++) {
          const DateTimeUtcFormated = dayjs(orderedComments[i].CreationDate);
          const dataFormated = DateTimeUtcFormated.format('DD/MM/YYYY');

          if (!data.hasOwnProperty(dataFormated)) {
            data[dataFormated] = [];
          }

          data[dataFormated].push({
            ...orderedComments[i],
            DateTimeTitle: dataFormated,
            DateTimeFormated: DateTimeUtcFormated,
          });
        }

        for (const newComments of Object.values(data)) {
          commentContent.comments = [...commentContent.comments, ...newComments];
        }
      }
      return {
        ...state,
        isLoadingComents: false,
        activeComment: commentContent,
      };
    case ActivitiesActionKeys.LIST_COMMENTS_FAILURE:
      return {
        ...state,
        isLoadingComents: false,
        error: action.payload,
      };

    case ActivitiesActionKeys.READ_COMMENTS_REQUEST:
      return {
        ...state,
      };
    case ActivitiesActionKeys.READ_COMMENTS_SUCCESS:
      let readCommentsContent: ICommentActive = state.activeComment;
      readCommentsContent.lastReadAt = dayjs();
      return {
        ...state,
        activeComment: { ...readCommentsContent },
      };
    case ActivitiesActionKeys.READ_COMMENTS_FAILURE:
      return {
        ...state,
      };

    case ActivitiesActionKeys.CREATE_COMMENT_REQUEST:
      return {
        ...state,
        isActionLoadingComent: true,
      };
    case ActivitiesActionKeys.CREATE_COMMENT_SUCCESS:
      let createCommentContent: ICommentActive = state.activeComment;
      if (
        action.payload?.comment &&
        !createCommentContent.comments.some(comment => {
          return comment.Id === action.payload.comment.Id;
        })
      ) {
        createCommentContent.lastReadAt = dayjs(action.payload.lastReadAt);
        const DateTimeUtcFormated = dayjs(action.payload.comment.CreationDate);
        const dataFormated = DateTimeUtcFormated.format('DD/MM/YYYY');

        const newComment: IComment = {
          ...action.payload.comment,
          DateTimeTitle: dataFormated,
          DateTimeFormated: DateTimeUtcFormated,
        };

        createCommentContent.comments = [...createCommentContent.comments, newComment];
      }
      return {
        ...state,
        isActionLoadingComent: false,
        activeComment: { ...createCommentContent },
      };
    case ActivitiesActionKeys.CREATE_COMMENT_FAILURE:
      return {
        ...state,
        isActionLoadingComent: false,
        error: action.payload,
      };

    case ActivitiesActionKeys.EDIT_COMMENT_REQUEST:
      return {
        ...state,
        isActionLoadingComent: true,
      };
    case ActivitiesActionKeys.EDIT_COMMENT_SUCCESS:
      let editCommentContent: ICommentActive = state.activeComment;
      if (action.payload) {
        const commentIndex = editCommentContent.comments.findIndex(comment => {
          return comment.Id === action.payload.Id;
        });
        if (commentIndex !== -1) {
          const DateTimeUtcFormated = dayjs(action.payload.CreationDate);
          const dataFormated = DateTimeUtcFormated.format('DD/MM/YYYY');

          const editComment: IComment = {
            ...action.payload,
            DateTimeTitle: dataFormated,
            DateTimeFormated: DateTimeUtcFormated,
          };

          editCommentContent.comments[commentIndex] = editComment;
        }
      }
      return {
        ...state,
        isActionLoadingComent: false,
        activeComment: { ...editCommentContent },
      };
    case ActivitiesActionKeys.EDIT_COMMENT_FAILURE:
      return {
        ...state,
        isActionLoadingComent: false,
        error: action.payload,
      };

    case ActivitiesActionKeys.DELETE_COMMENT_REQUEST:
      return {
        ...state,
        isActionLoadingComent: true,
      };
    case ActivitiesActionKeys.DELETE_COMMENT_SUCCESS:
      let deleteCommentContent: ICommentActive = state.activeComment;
      if (action.payload) {
        const commentIndex = deleteCommentContent.comments.findIndex(comment => {
          return comment.Id === action.payload.commentId;
        });
        if (commentIndex !== -1) {
          deleteCommentContent.comments.splice(commentIndex, 1);
        }
      }
      return {
        ...state,
        isActionLoadingComent: false,
        activeComment: { ...deleteCommentContent },
      };
    case ActivitiesActionKeys.DELETE_COMMENT_FAILURE:
      return {
        ...state,
        isActionLoadingComent: false,
        error: action.payload,
      };

    case ActivitiesActionKeys.SIGNALR_NEW_COMMENT:
      let signalRNewCommentContent: ICommentActive = state.activeComment;
      if (
        action.payload &&
        !signalRNewCommentContent.comments.some(comment => {
          return comment.Id === action.payload.Id;
        })
      ) {
        const DateTimeUtcFormated = dayjs(action.payload.CreationDate);
        const dataFormated = DateTimeUtcFormated.format('DD/MM/YYYY');

        const newComment: IComment = {
          ...action.payload,
          DateTimeTitle: dataFormated,
          DateTimeFormated: DateTimeUtcFormated,
        };

        signalRNewCommentContent.comments = [...signalRNewCommentContent.comments, newComment];
      }
      return {
        ...state,
        commentSignalRHasUpdate: action.payload,
        activeComment: { ...signalRNewCommentContent },
      };
    case ActivitiesActionKeys.SIGNALR_EDIT_COMMENT:
      let signalRUpdateCommentContent: ICommentActive = state.activeComment;
      if (action.payload) {
        const commentIndex = signalRUpdateCommentContent.comments.findIndex(comment => {
          return comment.Id === action.payload.Id;
        });
        if (commentIndex !== -1) {
          const DateTimeUtcFormated = dayjs(action.payload.CreationDate);
          const dataFormated = DateTimeUtcFormated.format('DD/MM/YYYY');

          const editComment: IComment = {
            ...action.payload,
            DateTimeTitle: dataFormated,
            DateTimeFormated: DateTimeUtcFormated,
          };

          signalRUpdateCommentContent.comments[commentIndex] = editComment;
        }
      }
      return {
        ...state,
        activeComment: { ...signalRUpdateCommentContent },
      };
    case ActivitiesActionKeys.SIGNALR_DELETE_COMMENT:
      let newCommentSignalRHasDelete = state.commentSignalRHasDelete;
      if (newCommentSignalRHasDelete) {
        const commentIndex = newCommentSignalRHasDelete.findIndex(comment => {
          return comment.Id === action.payload.Id;
        });
        if (commentIndex === -1) {
          newCommentSignalRHasDelete.push(action.payload);
        }
      } else {
        newCommentSignalRHasDelete = [action.payload];
      }
      return {
        ...state,
        commentSignalRHasDelete: [...newCommentSignalRHasDelete],
      };
    case ActivitiesActionKeys.SIGNALR_DELETE_COMMENT_CLEAR:
      let signalRDeleteCommentContent: ICommentActive = state.activeComment;
      const commentSignalRHasDelete = state.commentSignalRHasDelete;
      if (commentSignalRHasDelete) {
        for (const item of commentSignalRHasDelete) {
          const commentIndex = signalRDeleteCommentContent.comments.findIndex(comment => {
            return comment.Id === item.Id;
          });
          if (commentIndex !== -1) {
            signalRDeleteCommentContent.comments.splice(commentIndex, 1);
          }
        }
      }
      return {
        ...state,
        commentSignalRHasDelete: null,
        activeComment: { ...signalRDeleteCommentContent },
      };

    case ActivitiesActionKeys.SHARE_LINK_COMMENT_REQUEST:
      return {
        ...state,
        isLoadingShareLinkComment: true,
      };
    case ActivitiesActionKeys.SHARE_LINK_COMMENT_SUCCESS:
      return {
        ...state,
        shareLinkComment: action.payload.ShortURL,
        isLoadingShareLinkComment: false,
      };
    case ActivitiesActionKeys.SHARE_LINK_COMMENT_FAILURE:
      return {
        ...state,
        isLoadingShareLinkComment: false,
        error: action.payload,
      };
    case ActivitiesActionKeys.SHARE_LINK_COMMENT_CLEAR:
      return {
        ...state,
        shareLinkComment: undefined,
      };

    default:
      return state;
  }
};

export default ActivitiesReducer;
