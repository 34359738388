import {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import {
  CardOption,
  Container,
  Content,
  CustomBadge,
  Header,
  Main,
} from "./styles";

export type ActiveTabType = 'constructionSite' | 'allConstructionSites';

export type ITabMap = Map<ActiveTabType, ITab>;

export interface ITab {
  id: ActiveTabType;
  label: string;
  isDisabled: boolean;
  tagNumber?: number;
  newTagNumber?: number;
  children: ReactNode;
  customContentPadding?: string;
};

interface ITabs {
  defaultActiveTab: ActiveTabType;
  options: ITabMap;
  loadingExternalLink: boolean;
  onTabChange?: (tab: ActiveTabType) => void;
}

export interface TabsRef {
  onActiveTab: (tabId: ActiveTabType) => void;
}

const Tabs = forwardRef<TabsRef, ITabs>(({
  defaultActiveTab,
  options,
  loadingExternalLink,
  onTabChange,
}, ref) => {
  const [activeTab, setActiveTab] = useState<ActiveTabType>(defaultActiveTab);

  const optionsArray = Array.from(options);

  const handleActiveTab = useCallback((tabId: ActiveTabType) => {
    setActiveTab(tabId);
    if (onTabChange) onTabChange(tabId);
  }, [onTabChange]);

  useImperativeHandle(ref, () => ({
    onActiveTab: handleActiveTab,
  }), [handleActiveTab]);

  return (
    <Container>
      <Header>
        {optionsArray.map(([id, option]) => {
          const isActive = id === activeTab;
          const hasNewTag = option.newTagNumber && option.newTagNumber > 0;

          return (
            <CardOption
              key={id}
              isactive={`${!loadingExternalLink && isActive}`}
              isdisabled={`${loadingExternalLink || option.isDisabled}`}
              onClick={() => (loadingExternalLink || option.isDisabled) ? undefined : handleActiveTab(id)}
            >
              <div className="cardOptionsContainer">
                <span className="cardOptionsText">{option.label}</span>
                {(!loadingExternalLink && !hasNewTag && option.tagNumber && option.tagNumber > 0) ? (
                  <CustomBadge isactive={isActive}>
                    {option.tagNumber}
                  </CustomBadge>
                ) : null}
                {(!loadingExternalLink && option.newTagNumber && option.newTagNumber > 0) ? (
                  <CustomBadge isnew isactive={isActive}>
                    {option.newTagNumber}
                  </CustomBadge>
                ) : null}
              </div>
            </CardOption>
          )
        })}
      </Header>

      <Content style={{ padding: options.get(activeTab)?.customContentPadding }}>
        <Main>
          {options.get(activeTab)?.children}
        </Main>
      </Content>
    </Container>
  )
});

export default Tabs;
