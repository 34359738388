import { call, put } from 'redux-saga/effects';
import { NotificationApi } from 'Data/Notification.api';
import {
  NotificationActions,
  GetFileVersionHistory,
  SetNotificationAsView,
  SetReadNotification,
} from './Notification.actions';
import {
  notificationError,
  setNotificationToReadError,
  LOAD_VERSION_FILE,
} from 'Utils/Messages';
import toastHandler from 'Utils/toastHandler';
import { AuthUtils } from 'Utils/AuthUtils';
import { Mixpanel } from 'Utils/MixPanel';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';
import { ITenantData } from 'Data/interfaces/Tenant/ITenantData';

interface IReadNotificationTracking {
  folderId: string;
  constructionSiteId: number;
  quantity: number;
  userInfo?: IUserInforResponse;
  currentTenant?: ITenantData | null;
}

export function* listAllNotifications() {
  try {
    const { data } = yield call(NotificationApi.fetchAllNotification);
    yield put(NotificationActions.listNotificationSuccess(data.data));
  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : notificationError;
    toastHandler.showError(error);
    yield put(NotificationActions.listNotificationFailed(error));
  }
}

export function* listNewNotifications() {
  try {
    const { data } = yield call(NotificationApi.fetchNewNotification);
    yield put(NotificationActions.listNewNotificationSuccess(data.data));
    yield put(NotificationActions.setNotificationMap(data.info));
  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : notificationError;
    toastHandler.showError(error);
    yield put(NotificationActions.listNewNotificationFailed(error));
  }
}

export function* setNotificationsToRead({ payload }: SetReadNotification) {
  try {
    const { data } = yield call(NotificationApi.readNotification);

    Mixpanel.track({
      name: 'READ_ALL_NOTIFICATIONS',
      props: {},
      userInfo: payload.userInfo,
      currentListTenant: payload.currentTenant,
    });

    yield put(NotificationActions.setReadNotificationSuccess(data.data));
  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : setNotificationToReadError;
    toastHandler.showError(error);
    yield put(NotificationActions.setReadNotificationFailure(error));
  }
}

export function* setNotificationsToReadDBByIds({ payload }: SetNotificationAsView) {
  try {
    const userId = AuthUtils.getLoggedUser().userId;
    const notificationFiltered = payload.filter(notification => notification.UserFk !== userId);
    const notificationIds: number[] = [];
    let folderNotification: IReadNotificationTracking[] = [];

    if (notificationFiltered.length > 0) {
      const notifHash: any = {};

      for (const notification of notificationFiltered) {
        notificationIds.push(notification.NotificationId);

        if (!notifHash.hasOwnProperty(notification.ItemParentId)) {
          notifHash[notification.ItemParentId] = {
            folderId: notification.ItemParentId,
            constructionSiteId: notification.ConstructionSiteFk,
            quantity: 1,
            userInfo: notification.userInfoTracking,
            currentTenant: notification.currentTenantTracking,
          } as IReadNotificationTracking;
        } else {
          notifHash[notification.ItemParentId].quantity = notifHash[notification.ItemParentId].quantity + 1;
        }
      }

      folderNotification = Object.values(notifHash);
    }

    if (notificationIds.length > 0) {
      yield call(NotificationApi.readNotificationByIds, notificationIds);

      if (folderNotification.length > 0) {
        folderNotification.map(notif => {
          const { userInfo, currentTenant } = notif;
          delete notif.userInfo;
          return Mixpanel.track({
            name: 'READ_NOTIFICATION',
            props: notif,
            userInfo,
            currentListTenant: currentTenant,
          })
        })
      }
    }

  } catch (err: any) {
    const error = err && err.errors ? err.errors[0].Message : setNotificationToReadError;
    toastHandler.showError(error);
    yield put(NotificationActions.setReadNotificationFailure(error));
  }
}

export function* getFileVersionHistory({ payload }: GetFileVersionHistory) {
  try {
    const { data } = yield call(NotificationApi.fetchFileHistory, payload);
    yield put(NotificationActions.getFileVersionHistorySuccess(data.data));
  } catch (err) {
    const error = LOAD_VERSION_FILE;
    toastHandler.showError(error);
    yield put(NotificationActions.getFileVersionHistoryFailed(error));
  }
}
