import { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { GetAvatar } from "Utils/generateThumbnail";
import { formatDateFromNow } from "Utils/DateUtils";
import {
  IComment,
  IEditCommentRequest,
} from "Data/interfaces/Activities/IComments";
import ActionButtons from "../ActionButtons";
import { Icon } from "Components/UI";
import SpinCustom from "Components/UI/SpinCustom";
import { IMDTheme } from "Data/interfaces/MultiDomain/IMDTheme";
import AccessPermission from "Utils/AcessPermission";
import Input, { IInputData } from "../Input";
import { ActivitiesActions } from "Store/Activities/Activities.actions";
import {
  Wrapper,
  Container,
  Header,
  Content,
  OwnerBadge,
  ActionButton,
  InputContainer,
} from "./styles";

interface ICard {
  activityId?: string;
  item: IComment;
  isOwner?: boolean;
  first?: boolean;
  last?: boolean;
  isResponsible?: boolean;
  loadingShareLink?: boolean;
  active?: boolean;
  theme?: IMDTheme;
  editLoading?: boolean;
  onCopyLink: (comment: IComment) => void;
  onDelete: (comment: IComment) => void;
  onScrollToEnd?: () => void;
}

const Card: FC<ICard> = ({
  activityId,
  item,
  isOwner,
  first,
  last,
  isResponsible,
  loadingShareLink,
  active,
  theme,
  editLoading,
  onCopyLink,
  onDelete,
  onScrollToEnd,
}) => {
  const dispatch = useDispatch();

  const descriptionRef = useRef<HTMLSpanElement | null>(null);
  const [openString, setOpenString] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [activeUpdate, setActiveUpdate] = useState(false);

  const handleEdit = () => {
    setActiveUpdate(true);
    if (last && onScrollToEnd) {
      setTimeout(() => {
        onScrollToEnd();
      }, 100);
    }
  };

  const handleSubmit = (data: IInputData) => {
    if (!activityId) return;

    const request: IEditCommentRequest = {
      topicId: activityId,
      commentId: item.Id,
      data: { commentText: data.text },
    };
    dispatch(ActivitiesActions.editComment(request));
  };

  useEffect(() => {
    if (descriptionRef.current) {
      const { clientHeight, scrollHeight } = descriptionRef.current;
      setIsTruncated(scrollHeight > clientHeight);
    }
  }, []);

  return (
    <Wrapper first={first}>
      <Container active={active} isupdate={activeUpdate}>
        <Header>
          <span className="headerText">
            <span className="headerLeftText">
              <GetAvatar
                src={item.CreationAuthor.OriginalImageUrl}
                thumbSrc={item.CreationAuthor.ThumbnailImageUrl}
                name={item.CreationAuthor?.Nome || ''}
                size={24}
                fontSize={12}
              />
              <span className="name">{item.CreationAuthor.Nome || ''}</span>
            </span>
            {isResponsible && <OwnerBadge>Responsável</OwnerBadge>}
          </span>
          <span className="date">
            {formatDateFromNow(item.DateTimeFormated)}
          </span>
          <span className="actions">
            <ActionButton onClick={() => onCopyLink(item)}>
              {loadingShareLink && (
                <SpinCustom
                  size={10}
                  color={theme?.colors.surface.onSurfaceVariant}
                />
              )}
              {!loadingShareLink && (
                <Icon
                  icon="link"
                  customSize={12}
                  className="iconCopyLink"
                />
              )}
            </ActionButton>
            {isOwner && (
              <ActionButton onClick={handleEdit}>
                <Icon
                  icon="editar"
                  customSize={12}
                  className="iconEdit"
                />
              </ActionButton>
            )}
            <ActionButtons
              loadingShareLink={loadingShareLink}
              hasDeletePermission={isOwner || AccessPermission.isAdmin()}
              hasEditPermission={isOwner}
              onCopyLink={() => onCopyLink(item)}
              onEdit={handleEdit}
              onDelete={() => onDelete(item)}
            />
          </span>
        </Header>
        <Content open={openString}>
          {!activeUpdate && (
            <>
              <span ref={descriptionRef} className="description">
                {item.CommentText}
              </span>
              {isTruncated && (
                <span
                  className="seeMore"
                  onClick={() => setOpenString(prev => !prev)}
                >
                  {openString ? 'Ver menos' : 'Ver mais'}
                </span>
              )}
            </>
          )}
          {activeUpdate && (
            <InputContainer>
              <Input
                loading={editLoading}
                isUpdate
                defaultValue={item.CommentText}
                onSubmit={handleSubmit}
                onActiveCallback={setActiveUpdate}
              />
            </InputContainer>
          )}
        </Content>
      </Container>
    </Wrapper>
  )
}

export default Card;
