import { useMemo } from 'react';
import { INotificationData } from 'Data/interfaces/Notification/INotificationResponse';
import useHistoryPush from 'Hooks/useHistoryPush';
import { getUserAvatarOrThumb } from 'Utils/generateThumbnail';
import { formatDateByTime } from 'Utils/DateUtils';
import Ellipsis from 'Components/UI/Ellipsis';
import Badge from 'Components/UI/Badge';
import { Icon } from 'Components/UI';
import {
  DropdownNotification,
  DropdownStyled,
  LinkStyled,
  NotificationItem,
  ThereIsNotNotification,
  Title,
} from './styles';
import { getUnreadNotifications } from 'Utils/Sort';

type NotificationProps = {
  notifications: INotificationData[];
}

const Notification = ({ notifications }: NotificationProps) => {
  const { historyPush } = useHistoryPush();

  const items = useMemo(() => {
    return getUnreadNotifications(notifications);
  }, [notifications]);

  const valueNotification = useMemo(() => {
    return items?.reduce((acc, cur) => {
      if (cur.hasRead) return acc;
      return acc + 1;
    }, 0) || 0;
  }, [items]);

  const CountNotification = useMemo(() => {
    if (valueNotification <= 0) {
      return <Icon icon='notificacoes' customColor='#828282' customSize={24} />
    }

    return (
      <Badge value={valueNotification} type="circle">
        <Icon icon='notificacoes' customColor='#828282' customSize={24} />
      </Badge>
    );
  }, [valueNotification]);

  const handleRedirectItem = (url: string) => {
    historyPush(url);
  }

  const menu = (
    <DropdownNotification multiple={items.length > 0}>
      {items.length === 0 && (
        <ThereIsNotNotification>
          Não há novas atualizações
        </ThereIsNotNotification>
      )}

      {items.length > 0 && (
        <Title>Você tem {items.length} notificações</Title>
      )}

      {items.map((item: INotificationData) => (
        <NotificationItem
          key={item.NotificationId}
          onClick={() => handleRedirectItem(item.ItemUrl)}
        >
          <div className="main">
            <div className="content">
              <span className="avatar">
                {getUserAvatarOrThumb(item.AvatarFileNameUser, item.UserName)}
              </span>
              <span className="strong">
                <Ellipsis
                  phrase={item.shortDescription}
                  maxLength={53}
                  className="description"
                />
                {!item.hasRead && (
                  <span className="dot" />
                )}
              </span>
              <Ellipsis
                phrase={item.ItemName}
                maxLength={23}
                className="name"
              />
            </div>
            <div className="small">
              {formatDateByTime(new Date(item.DateCreateUtc))}
            </div>
          </div>
        </NotificationItem>
      ))}

      <LinkStyled
        key="redirectLink"
        onClick={() => historyPush('/notification')}
      >
        <span className="text">Ver histórico</span>
      </LinkStyled>
    </DropdownNotification>
  )

  return (
    <DropdownStyled
      overlay={menu}
      placement='bottomLeft'
      trigger={['click']}
    >
      <div>
        {CountNotification}
      </div>
    </DropdownStyled>
  );
};

export default Notification;
