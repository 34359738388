import { FC } from 'react';
import Button from 'Components/UI/Button';
import { Container } from './styles';

import emptyImg from 'Static/images/empty-notification.png';

interface IEmpty {
  onRedirect: (url: string) => void;
}

const Empty: FC<IEmpty> = ({ onRedirect }) => {
  return (
    <Container>
      <img src={emptyImg} alt="Notificações vazias" />
      <span className="title">Não há novas atualizações</span>
      <span className="description">
        Você está com tudo em dia. Notificaremos caso haja novidades.
      </span>
      <Button
        type="primary"
        className="button"
        onClick={() => onRedirect('/notification')}
      >
        Ver todas notificações
      </Button>
    </Container>
  )
}

export default Empty;
