import {
  EnumCommentsActionsReducer,
  ICommentsActionReducer,
  ICommentsStateReducer,
} from "./comments.model";

const initialState: ICommentsStateReducer = {
  copyingId: null,
  commentId: null,
  showDeleteModal: false,
  sentDelete: false,
  editComment: null,
  hasUpdate: false,
  removingId: null,
  visibleRange: {
    startIndex: 0,
    endIndex: 0,
  },
};

const commentsReducer = (
  state: ICommentsStateReducer,
  action: ICommentsActionReducer
): ICommentsStateReducer => {
  switch (action.type) {
    case EnumCommentsActionsReducer.SET_COPYING_ID:
      return { ...state, copyingId: action.payload };
    case EnumCommentsActionsReducer.SET_COMMENT_ID:
      return { ...state, commentId: action.payload };
    case EnumCommentsActionsReducer.SET_SHOW_DELETE_MODAL:
      return { ...state, showDeleteModal: action.payload };
    case EnumCommentsActionsReducer.SET_SENT_DELETE:
      return { ...state, sentDelete: action.payload };
    case EnumCommentsActionsReducer.SET_EDIT_COMMENT:
      return { ...state, editComment: action.payload };
    case EnumCommentsActionsReducer.SET_HAS_UPDATE:
      return { ...state, hasUpdate: action.payload };
    case EnumCommentsActionsReducer.SET_REMOVING_ID:
      return { ...state, removingId: action.payload };
    case EnumCommentsActionsReducer.SET_VISIBLE_RANGE:
      return { ...state, visibleRange: action.payload };
    default:
      return state;
  }
};

export { initialState, commentsReducer };
