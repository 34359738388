import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";

const getAttachmentsForUpload = (state: IGlobalReducerState) => state.activities.attachmentsForUpload;
const getAttachmentsUploading = (state: IGlobalReducerState) => state.activities.attachmentsUploading;
const getAttachmentsForCreate = (state: IGlobalReducerState) => state.activities.attachmentsForCreate;
const getIsLoadingAttachmentUploading = (state: IGlobalReducerState) => state.activities.isLoadingAttachmentUploading;
const getUploadAttachementCancelToken = (state: IGlobalReducerState) => state.activities.uploadAttachementCancelToken;
const getAttachementPreviewOn = (state: IGlobalReducerState) => state.activities.attachmentPreviewOn;
const getIsLoadingAttachmentDeleting = (state: IGlobalReducerState) => state.activities.isLoadingAttachmentDeleting;
const getAttachmentsError = (state: IGlobalReducerState) => state.activities.error;
const getAttachmentsNotShowError = (state: IGlobalReducerState) => state.activities.notShowError;
const getTopicStatusOptions = (state: IGlobalReducerState) => state.activities.topicStatusOptions;
const getActiveComment = (state: IGlobalReducerState) => state.activities.activeComment;
const getIsLoadingComments = (state: IGlobalReducerState) => state.activities.isLoadingComents;
const getIsActionLoadingComent = (state: IGlobalReducerState) => state.activities.isActionLoadingComent;
const getCommentSignalRHasUpdate = (state: IGlobalReducerState) => state.activities.commentSignalRHasUpdate;
const getCommentSignalRHasDelete = (state: IGlobalReducerState) => state.activities.commentSignalRHasDelete;
const getShareLinkComment = (state: IGlobalReducerState) => state.activities.shareLinkComment;
const getIsLoadingShareLinkComment = (state: IGlobalReducerState) => state.activities.isLoadingShareLinkComment;

export { 
  getAttachmentsForUpload,
  getAttachmentsUploading,
  getAttachmentsForCreate,
  getIsLoadingAttachmentUploading,
  getUploadAttachementCancelToken,
  getAttachementPreviewOn,
  getIsLoadingAttachmentDeleting,
  getAttachmentsError,
  getAttachmentsNotShowError,
  getTopicStatusOptions,
  getActiveComment,
  getIsLoadingComments,
  getIsActionLoadingComent,
  getCommentSignalRHasUpdate,
  getCommentSignalRHasDelete,
  getShareLinkComment,
  getIsLoadingShareLinkComment,
};
