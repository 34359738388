import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface.surface};
  margin-top: 6px;
`;

export const Header = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface.surface};
  padding: 0 10px;
`;

interface ICardOption {
  isactive: string;
  isdisabled: string;
}

export const CardOption = styled.div<ICardOption>`
  height: 32px;
  padding: 0 6px;
  background-color: ${({ theme }) => theme.colors.surface.surface};
  cursor: pointer;

  .cardOptionsContainer {
    height: 32px;
    display: flex;
    align-items: center;
  
    .cardOptionsText {
      font-size: 12px;
      font-weight: 600;
      line-height: 16.34px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
      margin-bottom: 4px;
    }
  
    ${({ isactive, theme }) => isactive === 'true' && css`
      height: 30px;
      border-bottom: 2px solid ${theme.colors.primary.primary};

      .cardOptionsText {
        margin-bottom: 0;
      }
    `}
  
    ${({ isdisabled, theme }) => isdisabled === 'true' && css`
      cursor: not-allowed;
      
      .cardOptionsText {
        font-weight: 400;
        color: ${theme.colors.surface.onSurfacePlaceholder};
      }
    `}
  }

  :hover {
    height: 28px;
    border-radius: 6px;
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
    margin-bottom: 4px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  max-height: 400px;
  background-color: ${({ theme }) => theme.colors.surface.surface};
`;

export const Main = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

interface ICustomBadge {
  isnew?: boolean;
  isactive?: boolean;
}

export const CustomBadge = styled.div<ICustomBadge>`
  min-width: 16px;
  width: auto;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.surface.surfaceOutline};
  margin-left: 6px;
  margin-bottom: ${({ isactive }) => isactive ? 0 : '4px'};
  padding: 0 5px;
  
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};

  ${({ isnew, theme }) => isnew && css`
    border-radius: 9.5px;
    padding: 0 9px;
    background-color: ${theme.colors.notification.notification};
    color: ${theme.colors.notification.onNotification};
  `}
`;
