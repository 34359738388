import { FC, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./comment.schema";
import Button from "Components/UI/Button";
import { Icon } from "Components/UI";
import emojiData from "@emoji-mart/data";
import EmojiPicker from "@emoji-mart/react";
import { Popover } from "antd";
import SpinCustom from "Components/UI/SpinCustom";
import { Container } from "./styles";

export interface IInputData {
  text: string;
}

interface IInput {
  disabled?: boolean;
  loading?: boolean;
  isUpdate?: boolean;
  defaultValue?: string;
  onSubmit: (data: IInputData) => void;
  onActiveCallback?: (active: boolean) => void;
}

const Input: FC<IInput> = ({
  disabled,
  loading,
  isUpdate,
  defaultValue,
  onSubmit,
  onActiveCallback,
}) => {
  const preventClose = useRef(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [active, setActive] = useState(false);
  const [visible, setVisible] = useState<boolean>();
  const [send, setSend] = useState(false);

  const {
    control,
    setFocus,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      text: defaultValue || ''
    },
    resolver: yupResolver(schema),
  });

  const handleVisibleChange = (currentVisible: boolean) => {
    setVisible(currentVisible);
    if (!currentVisible) {
      setFocus('text');
    }
  };

  const handleBlur = (onBlur: () => void) => {
    onBlur();
    setTimeout(() => {
      if (!visible) {
        if (!preventClose.current) {
          setActive(false);
          if (onActiveCallback) onActiveCallback(false);
        } else {
          setFocus('text');
        }
        preventClose.current = false;
      }
    }, 200);
  };

  const openSelectEmoticon = () => {
    preventClose.current = true;
    handleVisibleChange(!visible);
  };

  const handleSend = () => {
    preventClose.current = true;
    setTimeout(() => {
      setSend(true);
    }, 500);
    handleSubmit(onSubmit)();
  };

  const handleEmoji = (e: any) => {
    const textValue = getValues('text');
    setValue('text', `${textValue}${e.native}`);
  };

  const handleClose = () => {
    setSend(false);
    preventClose.current = false;
    reset();
    textAreaRef.current?.blur();
    setActive(false);
    if (onActiveCallback) onActiveCallback(false);
  }

  useEffect(() => {
    if (send && !loading) {
      handleClose();
    }
  }, [send, loading]);

  useEffect(() => {
    if (isUpdate) {
      setActive(true);
      setFocus('text');
    }
  }, [isUpdate, setFocus]);

  return (
    <Container active={active} disabled={disabled} isupdate={isUpdate}>
      <Controller
        name="text"
        control={control}
        disabled={disabled}
        render={({ field }) => (
          <textarea
            {...field}
            ref={(e) => {
              field.ref(e);
              textAreaRef.current = e;
            }}
            placeholder="Escreva um comentário..."
            maxLength={2500}
            rows={3}
            onFocus={() => setActive(true)}
            onBlur={() => handleBlur(field.onBlur)}
            className="input"
          />
        )}
      />
      {active && (
        <div className="footer">
          <Popover
            placement="top"
            open={visible}
            onOpenChange={handleVisibleChange}
            content={
              <div>
                <EmojiPicker
                  data={emojiData}
                  theme="light"
                  onEmojiSelect={handleEmoji}
                />
              </div>
            }
          >
            <Icon
              icon="smile"
              customSize={18}
              className="iconSmile"
              onClick={openSelectEmoticon}
            />
          </Popover>

          <div className="footerRight">
            {isUpdate && (
              <Button
                type="text"
                className="btnCancel"
                disabled={loading}
                onClick={loading ? undefined : handleClose}
              >
                Cancelar
              </Button>
            )}
            <Button
              type="primary"
              className="btnSubmit"
              onClick={loading ? undefined : handleSend}
            >
              {loading && (
                <SpinCustom color="white" size={12} marginRigth={6} />
              )}
              {loading ? 'Enviando...' : 'Enviar'}
            </Button>
          </div>
        </div>
      )}
    </Container>
  )
};

export default Input;
