import { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IGlobalReducerState } from "../../Store/Base/interface/IGlobalReducerState";
import TabsShell, { PathActiveEnum } from "../../Components/UI/TabsShell";
import PageHeader from "Components/UI/PageHeader/PageHeader";
import BreadCrumb, { IBreadcrumbItem } from "Components/UI/BreadCrumb";
import TableActionTopbar from "Components/UI/TableActionTopbar";
import ShowCreateActivity from "./Modals/ShowCreateActivity";
import KanbanVision from "./components/KanbanVision";
import ListVision from "./components/ListVision";
import ListButtons, { TypeButton } from "./components/ListButtons";
import useOptionsSelect from "./Modals/ShowCreateActivity/useOptionsSelect";
import ActivitiesCTAUpgrade from "./Modals/ActivitiesCTAUpgrade";
import useActivity from "./useActivity";
import UpdateStatus from "Components/UI/UpdateStatusList";
import ActivitySignalR from '../../Components/UI/SignalR/activity';
import ActiveLoading from "./components/ActiveLoading";
import Filters from "./components/Filters";
import ShareLink from "./Modals/ShareLink";
import { HeaderBreadcrumb, Wrapper } from "./styles";

interface IActivities {
  match: any;
}

export const BUTTON_ACTIVITY_LIST_KEY = 'BUTTON_ACTIVITY_LIST_KEY';

const breadCrumbs: IBreadcrumbItem[] = [
  {
    icon: 'menuHome',
    href: '/'
  },
];

const Activities: FC<Props> = ({
  match,
  isCollapsed,
  listActivities,
  isLoading,
  isLoadingCreate,
  isLoadingUpdate,
  isLoadingDelete,
  topicStatusOptions,
  topicTypesOptions,
  topicStagesOptions,
  editStage,
  topicPointsOptions,
  topicCommunicationTypesOptions,
  constructionSiteDisciplinesOptions,
  usersOptions,
  externalGlobalLoading,
  userInfo,
  planFree,
  endStatus,
  lastModifiedStatus,
  lastModifiedDate,
  updateStatus,
  topicSignalRHasUpdate,
  getOld,
  obrasList,
  newActivityGuid,
  shareLink,
  isLoadingShareLink,
  filters,
  allowOnlyStagesLinkedToDisciplinesInTopics,
  newEditLoading,
}) => {
  const {
    activeListButton,
    handleActiveListButton,
    upgradePlanRedirect,
    getActionsToolbar,
    searchActivitiesDefault,
    setSearchActivities,
    setSearchLoading,
    height,
    width,
    query,
    handleOpenCreateActivity,
    handleOpenShowActivity,
    showCreateActivity,
    handleCloseShowActivity,
    currentShowActivity,
    lastModifiedLoading,
    showFilters,
    handleShowFilters,
    showFiltersShare,
    handleShowFiltersShare,
    hasFilters,
    addFilter,
    updateFilter,
    deleteFilter,
    firstRenderFilters,
    lastFilterUpdateId,
    blockFilterDropdown,
  } = useActivity({
    constructionSiteId: match.params.constructionSiteId,
    listActivities,
    topicSignalRHasUpdate,
    userInfo,
    externalGlobalLoading,
    obrasList,
    shareLink,
    filters,
  });

  const {
    visibilityOptions,
    priorityOptions,
    priorityNoBgOptions,
    statusOptions,
    typeOptions,
    stageOptions,
    pointsOptions,
    communicationOptions,
    userOptions,
    disciplineOptions,
  } = useOptionsSelect({
    topicStatusOptions,
    topicTypesOptions,
    topicStagesOptions,
    topicPointsOptions,
    topicCommunicationTypesOptions,
    constructionSiteDisciplinesOptions,
    usersOptions,
  });

  const dontHaveActivitiesAccess = planFree ||
    (userInfo?.UserStoreItem.StorePlan?.Order
      && userInfo?.UserStoreItem.StorePlan?.Order < 3);

  if (match?.params?.constructionSiteId) {
    const currentObra = obrasList?.find(obra => obra.ConstructionSiteId === Number(match.params.constructionSiteId));

    breadCrumbs[0] = {
      href: `/obras/detail/${match.params.constructionSiteId}`,
      description: currentObra?.Name || 'A Obra',
    };
    breadCrumbs[1] = {
      href: `/obras/activities/detail/${match.params.constructionSiteId}`,
      description: 'Atividades',
    };
  };

  const buttons: TypeButton = {
    'kanban': {
      nameId: 'kanban',
      placement: 'Kanban',
      icon: 'listKanban',
      iconSize: 16,
    },
    'list': {
      nameId: 'list',
      placement: 'Lista',
      icon: 'listList',
      iconSize: 16,
    },
    // será implementado posteriormente
    // 'detailVision': {
    //   nameId: 'detailVision',
    //   placement: 'Lista Detalhada',
    //   icon: 'listDetailVision',
    //   iconSize: 16,
    // },
  };

  const isAllLoading = isLoading || externalGlobalLoading;
  const showKanban = !isAllLoading && activeListButton === 'kanban';
  const showList = !isAllLoading && activeListButton === 'list';
  // const showDetailVision = !isLoading && activeListButton === 'detailVision';

  return (
    <Wrapper isCollapsed={isCollapsed}>
      <ActivitySignalR csId={match.params.constructionSiteId} />
      <TabsShell
        ConstructionSiteId={match.params.constructionSiteId}
        active={PathActiveEnum.activities}
        loading={false}
        match={match}
      />
      <PageHeader
        name={'Atividades'}
      >
        <HeaderBreadcrumb>
          <BreadCrumb breadcrumbs={breadCrumbs} />
          <UpdateStatus
            status={lastModifiedStatus}
            date={lastModifiedDate}
            loading={isLoading && !lastModifiedLoading}
            custom
          />
        </HeaderBreadcrumb>
        {dontHaveActivitiesAccess && (
          <ActivitiesCTAUpgrade
            onConfirm={upgradePlanRedirect}
            planFree={!planFree}
            order={userInfo?.UserStoreItem.StorePlan?.Order}
            role={userInfo?.CurrentRoleFk}
          />
        )}
        {!dontHaveActivitiesAccess && (
          <>
            <div className="headerWrapper">
              <TableActionTopbar
                buttons={getActionsToolbar() || []}
                defaultValue={searchActivitiesDefault}
                onSearch={setSearchActivities}
                onSearchLoading={setSearchLoading}
                inputPlaceholder="Pesquise pela tarefa, código, disciplina, etc."
                inputClassName={'inputTopBar'}
                LeftComponent={(
                  <ListButtons
                    storageKey={BUTTON_ACTIVITY_LIST_KEY}
                    buttons={buttons}
                    activeButton={activeListButton}
                    onActiveOption={handleActiveListButton}
                  />
                )}
                showFilters={showFilters}
              />
              {showFilters && (
                <Filters
                  filters={filters}
                  addFilter={addFilter}
                  updateFilter={updateFilter}
                  deleteFilter={deleteFilter}
                  onShowFilters={handleShowFilters}
                  firstRenderFilters={firstRenderFilters}
                  lastFilterUpdateId={lastFilterUpdateId}
                  blockFilterDropdown={blockFilterDropdown}
                />
              )}
            </div>
            <div className="mainWrapper">
              {isAllLoading && (
                <ActiveLoading
                  activeListButton={activeListButton}
                  height={height}
                />
              )}
              {showKanban && (
                <KanbanVision
                  listActivities={listActivities}
                  height={height}
                  searchActivity={query.get('searchActivity') || ''}
                  onOpenCreateActivity={handleOpenCreateActivity}
                  onOpenShowActivity={handleOpenShowActivity}
                  endStatus={endStatus}
                  showFilters={showFilters}
                  filters={filters}
                  hasFilters={hasFilters}
                />
              )}
              {showList && (
                <ListVision
                  listActivities={listActivities}
                  height={height}
                  width={width}
                  searchActivity={query.get('searchActivity') || ''}
                  onOpenCreateActivity={handleOpenCreateActivity}
                  onOpenShowActivity={handleOpenShowActivity}
                  endStatus={endStatus}
                  showFilters={showFilters}
                  filters={filters}
                  hasFilters={hasFilters}
                />
              )}
            </div>
          </>
        )}
      </PageHeader>

      {showCreateActivity && (
        <ShowCreateActivity
          visible={showCreateActivity}
          onCancel={() => handleCloseShowActivity()}
          loading={isLoadingCreate}
          loadingExternalLink={isAllLoading}
          loadingDelete={isLoadingDelete}
          loadingUpdate={isLoadingUpdate}
          editStage={editStage}
          title="Nova atividade"
          csId={Number(match.params.constructionSiteId)}
          topicStatus={topicStatusOptions}
          topicStages={topicStagesOptions}
          topicPoints={topicPointsOptions}
          topicCommunicationType={topicCommunicationTypesOptions}
          updateActivity={
            (listActivities?.activities && currentShowActivity)
              ? listActivities.activities[currentShowActivity]
              : null
          }
          getOld={getOld}
          visibilityOptions={visibilityOptions}
          priorityOptions={priorityOptions}
          priorityNoBgOptions={priorityNoBgOptions}
          statusOptions={statusOptions}
          typeOptions={typeOptions}
          stageOptions={stageOptions}
          pointsOptions={pointsOptions}
          communicationOptions={communicationOptions}
          userOptions={userOptions}
          disciplineOptions={disciplineOptions}
          disciplines={constructionSiteDisciplinesOptions}
          updateStatus={updateStatus}
          allowOnlyStagesLinkedToDisciplinesInTopics={allowOnlyStagesLinkedToDisciplinesInTopics}
          createStageLoading={newEditLoading}
          onOpenShowActivity={handleOpenShowActivity}
          newActivityGuid={newActivityGuid}
          userInfo={userInfo}
        />
      )}
      {showFiltersShare && (
        <ShareLink
          visible={showFiltersShare}
          context={userInfo?.CurrentTenantContext}
          shareLink={shareLink}
          loading={isLoadingShareLink}
          onCancel={() => handleShowFiltersShare(false)}
        />
      )}
    </Wrapper>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  ...state.activities,
  isCollapsed: state.obra.isCollapsed,
  userInfo: state.auth.userInfo,
  planFree: state.auth.planFree,
  obrasList: state.obra.ObrasList,
  allowOnlyStagesLinkedToDisciplinesInTopics: state.obra.Obra.AllowOnlyStagesLinkedToDisciplinesInTopics,
  newEditLoading: state.obra.newEditLoading,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IActivities;

export default connector(Activities);
