import { FC, ReactNode, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { TenantActions } from '../../Store/Tenant/Tenant.actions';
import { ObraActions } from '../../Store/Obra/Obra.actions';
import { NomenclaturaActions } from '../../Store/Nomenclatura/Nomenclatura.actions';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import LeftBar from './LeftBar';
import TopBar from './TopBar';
import { ShellHostStyled } from './styles';

export interface IShellHost {
  children?: ReactNode;
  hiddenShell: boolean;
}

const ShellHost: FC<Props> = ({
  children,
  hiddenShell,
  isCollapsed,
}) => {
  const dispatch = useDispatch();

  const toggleCollapse = () => {
    dispatch(ObraActions.toggleCollapse(!isCollapsed));
  };

  useEffect(() => {
    if (window.innerWidth <= 1366) {
      dispatch(ObraActions.toggleCollapse(true));
    }
  }, []);

  useEffect(() => {
    dispatch(TenantActions.getTenantRoles());
    dispatch(TenantActions.getTenantUsers());
    dispatch(TenantActions.listUserTenants());
    dispatch(ObraActions.getDisciplines());
    dispatch(NomenclaturaActions.getNomenclature());
  }, [dispatch]);

  return (
    <ShellHostStyled hidden={hiddenShell} isCollapsed={`${isCollapsed}`}>
      <div className="content">
        <TopBar onCollapseClick={toggleCollapse} isCollapsed={isCollapsed} />
        <LeftBar isCollapsed={isCollapsed} />
        <main className="main">
          {children}
        </main>
      </div>
    </ShellHostStyled>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  isCollapsed: state.obra.isCollapsed,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IShellHost;

export default connector(ShellHost);
