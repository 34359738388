import dayjs, { Dayjs } from 'dayjs';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import "dayjs/locale/pt-br";

dayjs.locale('pt-br');
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const daysOfWeek = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

export const formatDateByTime = (date: Date | Dayjs): string => {
  const now = dayjs();
  const currDate = dayjs(date);

  if (currDate.isBefore(now.startOf('week'))) {
    return currDate.format('DD/MM/YYYY');
  }

  if (currDate.isYesterday()) {
    return 'Ontem';
  }

  if (!currDate.isToday()) {
    return daysOfWeek[currDate.day()];
  }

  if (currDate.isBefore(now.subtract(1, 'hour'))) {
    return currDate.format('HH:mm');
  }

  return currDate.fromNow();
};

export const formatDateFromNow = (date: Dayjs) => {
  const currDate = date.local();

  if (currDate.isToday()) {
    return currDate.fromNow();
  }

  if (currDate.isYesterday()) {
    return 'Ontem';
  }

  return currDate.format('DD/MM/YYYY');
};

export const formatTitleHistoryDate = (date: string): string => {
  const formatDate = date.split('/').reverse().join('-');
  const currDate = dayjs(formatDate);

  if (currDate.isToday()) {
    return 'Hoje';
  }

  if (currDate.isYesterday()) {
    return 'Ontem';
  }

  return date;
};

export const formatTitleHistoryDayjs = (date: Dayjs): string => {
  if (date.isToday()) {
    return 'Hoje';
  }

  if (date.isYesterday()) {
    return 'Ontem';
  }

  return date.format('DD/MM/YYYY');
};

export const datetimeToString = (date: Date): string => {
  const currDate = dayjs(date);
  return currDate.format('DD/MM/YYYY [às] HH:mm');
};

export const dateToString = (date: Date): string => {
  const currDate = dayjs(date);
  return currDate.format('DD/MM/YYYY');
};

export const dateToLocaleString = (date: Date | string): string => {
  const currDate = dayjs(date);
  return currDate.format('DD/MM/YYYY');
};

export const dateUpdateListFiles = (date: Date, custom?: boolean): string => {
  const now = dayjs();
  const currDate = dayjs(date);

  if (!custom) {
    if (currDate.isSame(now, 'minute')) {
      return 'Atualizado alguns segundos atrás';
    }

    if (currDate.isSame(now, 'hour')) {
      return 'Atualizado alguns minutos atrás';
    }
  }

  return currDate.format("[Atualizado em] DD [de] MMMM [de] YYYY[,] [às] HH:mm");
}

export const billingDate = (day: number) => {
  const now = new Date();
  const year = now.getFullYear();
  let useMonth = 0;

  if (day >= now.getDate()) {
    const month = now.getMonth();
    if (month === 12) {
      useMonth = 1;
    } else {
      useMonth = month + 1;
    }

    const payDate = new Date(`${year}-${useMonth}-${day}`);
    return dateToString(payDate);
  }

  const month = now.getMonth();
  if (month === 11) {
    useMonth = 1;
  } else if (month === 12) {
    useMonth = 2;
  } else {
    useMonth = month + 2;
  }

  const payDate = new Date(`${year}-${useMonth}-${day}`);
  return dateToString(payDate);
}

export const isExpiredDate = (date: Date | string) => {
  const now = dayjs();
  const formatDate = dayjs(date);
  
  if (!formatDate.isToday() && dayjs(formatDate).isBefore(now)) {
    return true;
  }

  return false;
};

export const isEqualDate = (fDate: string | Date, SDate: string | Date) => {
  const firstDate = dayjs(fDate);
  const secondDate = dayjs(SDate);

  return dayjs(firstDate).isSame(secondDate);
};
