import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { INotificationData } from "Data/interfaces/Notification/INotificationResponse";
import Card from "./components/Card";
import { INotificationsMapAux } from "Data/interfaces/Notification/INotificationsMapAux";
import Empty from "./components/Empty";
import { Container } from "./styles";

interface IContent {
  notifications: INotificationData[];
  onRedirect: (url: string) => void;
  onMarkAsRead: (readRows: INotificationsMapAux[]) => void;
}

const Content: FC<IContent> = ({ notifications, onRedirect, onMarkAsRead }) => {
  const cardRef0 = useRef<HTMLDivElement>(null);
  const cardRef1 = useRef<HTMLDivElement>(null);
  const cardRef2 = useRef<HTMLDivElement>(null);
  const cardRef3 = useRef<HTMLDivElement>(null);
  const cardRef4 = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(300);

  useEffect(() => {
    const updateHeight = (count: number) => {
      const maxHeight = 400;

      if (count >= 5) {
        setContainerHeight(maxHeight);
        return;
      }
      if (count === 0) {
        setContainerHeight(300);
        return;
      }

      const ref0Height = cardRef0.current?.offsetHeight || 0;
      const ref1Height = cardRef1.current?.offsetHeight || 0;
      const ref2Height = cardRef2.current?.offsetHeight || 0;
      const ref3Height = cardRef3.current?.offsetHeight || 0;
      const ref4Height = cardRef4.current?.offsetHeight || 0;
      const dynamicHeight = ref0Height + ref1Height + ref2Height + ref3Height + ref4Height + 24;
      setContainerHeight(
        ((dynamicHeight > maxHeight) || dynamicHeight < ref0Height)
          ? maxHeight
          : dynamicHeight
      );
    };

    setTimeout(() => updateHeight(notifications.length));
  }, [notifications]);

  const getRef = useCallback((index: number) => {
    if (index === 0) return cardRef0;
    if (index === 1) return cardRef1;
    if (index === 2) return cardRef2;
    if (index === 3) return cardRef3;
    if (index === 4) return cardRef4;
    return undefined;
  }, []);

  return (
    <Container height={containerHeight}>
      {notifications.length > 0 && (
        <Virtuoso
          style={{ flex: 1, width: '100%', height: containerHeight }}
          totalCount={notifications.length}
          data={notifications}
          itemContent={(index, item) => (
            <div
              key={`new-notif-${index}`}
              ref={getRef(index)}
            >
              <Card
                item={item}
                onRedirect={onRedirect}
                onMarkAsRead={onMarkAsRead}
              />
            </div>
          )}
        />
      )}
      {notifications.length === 0 && (
        <Empty onRedirect={onRedirect} />
      )}
    </Container>
  );
};

export default Content;
