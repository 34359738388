export enum IconEnum {
  ARQUIVO2D = 'arquivo2d',
  ARQUIVO3D = 'arquivo3d',
  XLS = 'arquivoExcel',
  PDF = 'arquivoPdf',
  TXT = 'arquivoTxt',
  IMAGE = 'arquivoImg',
  FOLDER = 'arquivar'
}

export enum NotificationCategoryEnum {
  Arquivo = 1,
  Obra = 2,
  Plotagem = 3,
  Usuario = 4,
  Atividade = 5,
}

export enum NotificationActionEnum {
  NovoArquivo = 1,
  NovaVersaoArquivo = 2,
  ArquivoMovido = 3,
  ArquivoExcluido = 4,
  NovaPasta = 5,
}
