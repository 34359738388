import styled, { css } from "styled-components";

interface IContainer {
  height: number;
  loading?: boolean;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  height: ${({ height }) => height}px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.surface.surface};
  overflow-y: auto;

  ${({ loading }) => loading && css`
    max-height: 380px;
    overflow: hidden;
  `}
`;
