import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;

  .title {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    text-align: center;
    color: ${({ theme }) => theme.colors.surface.onSurface};
    margin-top: 24px;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: center;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
    margin-top: 6px;
  }

  .button {
    margin-top: 12px;
  }
`;
