import { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import useHistoryPush from 'Hooks/useHistoryPush';
import { INotificationData } from 'Data/interfaces/Notification/INotificationResponse';
import Badge from 'Components/UI/Badge';
import { Icon } from 'Components/UI';
import ActionsContent from './components/ActionsContent';
import Button from 'Components/UI/Button';
import { NotificationActions } from 'Store/Notification/Notification.actions';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';
import { INotificationsMapAux } from 'Data/interfaces/Notification/INotificationsMapAux';
import { ITenantData } from 'Data/interfaces/Tenant/ITenantData';
import { ActiveTabType, TabsRef } from './components/Tabs';
import {
  PopoverStyled,
  NotificationButton,
  DropdownNotification,
  Header,
  Footer,
  Main,
} from './styles';

type NewNotificationProps = {
  constructionSiteId: number | null;
  allNotifications: INotificationData[];
  loadingExternalLink: boolean;
  userInfo?: IUserInforResponse;
  currentTenant?: ITenantData | null;
}

const NewNotification = ({
  constructionSiteId,
  allNotifications,
  loadingExternalLink,
  userInfo,
  currentTenant,
}: NewNotificationProps) => {
  const dispatch = useDispatch();
  const { historyPush } = useHistoryPush();

  const tabsRef = useRef<TabsRef>(null);
  const [open, setOpen] = useState(false);
  const [currentActiveTab, setCurrentActiveTab] = useState<ActiveTabType>('allConstructionSites');

  const notifications = useMemo(() => {
    const all: INotificationData[] = [];
    let allCount = 0;
    const cs: INotificationData[] = [];
    let csCount = 0;

    for (const notification of allNotifications) {
      if (!notification.hasRead) {
        all.push(notification);
        allCount++;

        if (
          constructionSiteId &&
          notification.ConstructionSiteFk === constructionSiteId
        ) {
          cs.push(notification);
          csCount++;
        }
      }
    }

    return { all, allCount, cs, csCount };
  }, [constructionSiteId, allNotifications]);

  const CountNotification = useMemo(() => {
    if (notifications.allCount <= 0) {
      return <Icon icon='notificacoes' customColor='#828282' customSize={24} />
    }

    return (
      <Badge value={notifications.allCount} type="circle">
        <Icon icon='notificacoes' customColor='#828282' customSize={24} />
      </Badge>
    );
  }, [notifications.allCount]);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleRedirect = (url: string) => {
    historyPush(url);
    handleOpenChange(false);
  };

  const handleMarkAsRead = (readRows: INotificationsMapAux[]) => {
    const notificationsMap: INotificationsMapAux[] = [];
    const notificationsMapIds: number[] = [];

    for (const readRow of readRows) {
      if (readRow?.NotificationId && readRow.NotificationId > 0) {
        const item = {
          NotificationId: !readRow.hasRead ? readRow.NotificationId : 0,
          UserFk: readRow.UserFk,
          userInfoTracking: userInfo,
          currentTenantTracking: currentTenant,
        } as INotificationsMapAux;
        notificationsMap.push(item);
        notificationsMapIds.push(readRow.NotificationId);
      }
    }

    if (notificationsMap?.length > 0) {
      dispatch(NotificationActions.setNotificationAsView(notificationsMap));
      dispatch(NotificationActions.setNotificationAsRead(notificationsMapIds));
    }
  };

  const handleMarkAllAsRead = () => {
    if (currentActiveTab === 'allConstructionSites') {
      dispatch(NotificationActions.setReadNotification({ userInfo }));
    } else {
      handleMarkAsRead(notifications.cs);
    }
  };

  const showFooter = useMemo(() => {
    if (currentActiveTab === 'allConstructionSites') {
      return notifications.allCount > 0;
    }
    return notifications.csCount > 0;
  }, [notifications.allCount, notifications.csCount, currentActiveTab]);

  const menu = (
    <DropdownNotification>
      <Header>
        <span className="title">Notificações</span>
        <Icon
          icon="cancelar"
          className="closeIcon"
          onClick={() => handleOpenChange(false)}
        />
      </Header>

      <Main>
        <ActionsContent
          tabsRef={tabsRef}
          constructionSiteId={constructionSiteId}
          notifications={notifications}
          loadingExternalLink={false}
          onRedirect={handleRedirect}
          onMarkAsRead={handleMarkAsRead}
          onTabChange={setCurrentActiveTab}
        />
      </Main>

      {showFooter && (
        <Footer>
          <Button
            type="text"
            className="btnMarkAllAsRead"
            onClick={handleMarkAllAsRead}
          >
            <Icon
              icon="doubleCheck"
              customSize={16}
              className="iconBtnMarkAllAsRead"
            />
            Marcar todas como lidas
          </Button>
          <Button
            type="primary"
            className="btnViewAll"
            onClick={() => handleRedirect('/notification')}
          >
            Ver todas notificações
          </Button>
        </Footer>
      )}
    </DropdownNotification>
  )

  return (
    <PopoverStyled
      content={menu}
      placement="bottomLeft"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      overlayInnerStyle={{ padding: 0, borderRadius: 12 }}
      showArrow={false}
    >
      <NotificationButton>
        {CountNotification}
      </NotificationButton>
    </PopoverStyled>
  );
};

export default NewNotification;
