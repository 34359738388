import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { ActivitiesActions } from 'Store/Activities/Activities.actions';
import { ICommentApiResponse } from 'Data/interfaces/Activities/IComments';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';

export interface ICommentSignalR {
  topicId: string;
  userInfo?: IUserInforResponse;
}

const CommentSignalR: FC<ICommentSignalR> = ({ topicId, userInfo }) => {
  const dispatch = useDispatch();

  const [connection, setConnection] = useState<HubConnection>();

  const handleNoIsAuthor = useCallback((creationAuthorFk?: string) => {
    if (
      userInfo?.Id && creationAuthorFk &&
      userInfo.Id !== creationAuthorFk
    ) {
      return true;
    }
    return false;
  }, [userInfo]);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_BASE_API_URL?.toString().replace("/api", "")}/hubs/topics?topicId=${topicId}`
      )
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    newConnection.serverTimeoutInMilliseconds = 100000;
    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (!connection) return;

    connection
      .start()
      .then((result) => {
        connection.on('NewComment', (data: string) => {
          const response: ICommentApiResponse = JSON.parse(data);
          const noIsAuthor = handleNoIsAuthor(
            response?.CreationAuthorFk || response?.CreationAuthor?.Id
          );
          if (noIsAuthor) {
            dispatch(ActivitiesActions.signalRNewComment(response));
          }
        });
        connection.on('UpdateComment', (data: string) => {
          const response: ICommentApiResponse = JSON.parse(data);
          const noIsAuthor = handleNoIsAuthor(
            response?.CreationAuthorFk || response?.CreationAuthor?.Id
          );
          if (noIsAuthor) {
            dispatch(ActivitiesActions.signalREditComment(response));
          }
        });
        connection.on('DeleteComment', (data: string) => {
          const response: ICommentApiResponse = JSON.parse(data);
          const noIsAuthor = handleNoIsAuthor(response?.CreationAuthorFk);
          if (noIsAuthor) {
            dispatch(ActivitiesActions.signalRDeleteComment(response));
          }
        });
      })
      .catch((e) => console.log('Connection failed: ', e));

    return () => {
      connection.stop();
    }
  }, [connection]);

  return <></>;
};

export default memo(CommentSignalR, () => true);
