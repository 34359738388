import { ChangeEvent, FC, useEffect, useState } from 'react';
import { TypeCustomError } from 'Pages/Activities/Modals/ShowCreateActivity';
import {
  TypePathUpdateData,
  TypeValueUpdateData,
} from 'Data/interfaces/Activities/IUpdateActivityRequest';
import Tooltip from 'Components/UI/Tooltip';
import Ellipsis from 'Components/UI/Ellipsis';
import styles from './InputTitle.module.scss';

export interface IInputTitle {
  name: string;
  path?: TypePathUpdateData;
  defaultValue: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  maxLenght?: number;
  errorId?: TypeCustomError;
  error?: boolean;
  tooltipErrorText?: string;
  onConfirm: (value: string) => void;
  onDynamicUpdateSubmit?: (path: TypePathUpdateData, values: TypeValueUpdateData) => void;
  onCustomError?: (error: TypeCustomError) => void;
  onClearCustomError?: (error: TypeCustomError) => void;
  isUpdate?: boolean;
  havePermission?: boolean;
}

const InputTitle: FC<IInputTitle> = ({
  name,
  path,
  defaultValue,
  placeholder,
  className,
  disabled,
  maxLenght,
  errorId,
  error,
  tooltipErrorText,
  onConfirm,
  onDynamicUpdateSubmit,
  onCustomError,
  onClearCustomError,
  isUpdate,
  havePermission,
}) => {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (isUpdate) {
      setIsActive(false);
    }
  }, [isUpdate]);

  const handleChange = (value: ChangeEvent<HTMLInputElement>) => {
    if (errorId && onCustomError && onClearCustomError) {
      if (value.target.value) {
        onClearCustomError(errorId);
      } else {
        onCustomError(errorId);
      }

      onConfirm(value.target.value);
    }
  }

  const handleBlur = (value: ChangeEvent<HTMLInputElement>) => {
    if (errorId && onCustomError && onClearCustomError) {
      if (value.target.value) {
        onClearCustomError(errorId);
      } else {
        onCustomError(errorId);
      }

      if (isUpdate && path && onDynamicUpdateSubmit) {
        onDynamicUpdateSubmit(path, value.target.value);
      } else {
        onConfirm(value.target.value);
      }
    }

    setIsActive(false);
  }

  return (
    isActive ? (
      <div className={styles['wrapper']}>
        <input
          name={name}
          value={defaultValue}
          disabled={disabled}
          maxLength={maxLenght}
          placeholder={placeholder}
          className={`
            ${styles['inputDefault']}
            ${className ? className : ''}
            ${(error && !isUpdate) ? styles['inputDefaultError'] : ''}
          `}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus={isActive}
        />
      </div>
    ) : (
      <Tooltip
        overlay={
          (error && isUpdate && tooltipErrorText)
            ? tooltipErrorText
            : undefined
        }
      >
        <div
          className={`
            ${styles['noActiveWrapper']}
            ${(error && isUpdate) ? styles['noActiveWrapperError'] : ''}
            ${!havePermission ? styles['nPCursor'] : ''}
          `}
          onClick={() => havePermission ? setIsActive(true) : undefined}
        >
          <span className={`
            ${styles['noActiveText']}
            ${(!defaultValue && placeholder) ? styles['noActiveTextPlaceholder'] : ''}
            ${(error && !isUpdate) ? styles['noActiveTextError'] : ''}
          `}>
            <Ellipsis
              phrase={defaultValue || placeholder || ''}
              maxLength={74}
              maxTooltipWidth={400}
            />
          </span>
        </div>
      </Tooltip>
    )
  );
};

export default InputTitle;
