import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Icon } from "Components/UI";
import Button from "Components/UI/Button";
import Modal from "Components/UI/Modal/Modal";
import { Checkbox, ConfigProvider, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Form from "Components/UI/Form/Form";
import schema from "./stages.schema";
import Field from "Components/UI/Field";
import SearchInput from "Components/UI/SearchInput";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import { IStage } from "Data/interfaces/Obra/IObraStages";
import toastHandler from 'Utils/toastHandler';
import Tooltip from "Components/UI/Tooltip";
import ItemDiscipline from "Pages/EditObra/components/Stages/components/ItemDiscipline";
import ConfirmDefault from "Pages/EditObra/components/Stages/components/ConfirmDefault";
import {
  InputStageProps,
  InputStageRequest,
} from "Pages/EditObra/components/Stages/stages.model";
import { IDisciplineDataStage } from "Data/interfaces/Obra/IObraData";
import { IDisciplineResponse } from "Data/interfaces/Obra/IDisciplineResponse";
import {
  BtnContainer,
  Container,
  Content,
  FormContainer,
  Main,
  SearchContainer,
  Title,
} from "./styles";

interface ICreateStage {
  visible: boolean;
  editName: string;
  loading?: boolean;
  disciplines?: IDisciplineResponse[];
  disciplinesSelectedsFk?: number[];
  allowOnlyStagesLinkedToDisciplinesInTopics?: boolean;
  stages: IStage[];
  onSubmit: (
    values: InputStageRequest,
    topicStageId?: number,
    order?: number,
  ) => void;
  onCancel: () => void;
}

const CreateStage: FC<ICreateStage> = ({
  visible,
  editName,
  loading,
  disciplines,
  disciplinesSelectedsFk,
  allowOnlyStagesLinkedToDisciplinesInTopics,
  stages,
  onSubmit,
  onCancel,
}) => {
  const theme = useSelector(getTheme);

  const isDefaultLocked = stages.length === 0;
  const [isSubmit, setIsSubmit] = useState(false);
  const [acronym, setAcronym] = useState('');
  const [stageName, setStageName] = useState(editName || '');
  const [checked, setChecked] = useState(isDefaultLocked || false);
  const [search, setSearch] = useState('');
  const [disciplinesList, setDisciplinesList] = useState<IDisciplineDataStage[]>([]);
  const [showConfirmDefault, setShowConfirmDefault] = useState(false);
  const [submitValues, setSubmitValues] = useState<InputStageRequest | null>(null);

  const editStage = useMemo(() => {
    if (editName) {
      const stageExistIndex = stages.findIndex(stage => {
        return stage.Name?.toLowerCase() === editName?.toLowerCase();
      });
      if (stageExistIndex !== -1 && !stages[stageExistIndex]?.Acronym) {
        return stages[stageExistIndex];
      }
    }
    return null;
  }, [editName, stages]);

  const disabled = useMemo(() => {
    if (!editStage) {
      return !acronym || !stageName;
    }

    const disciplineIds = disciplinesList
      .filter(discipline => discipline.IsChecked)
      .map(discipline => discipline.ConstructionSiteDisciplinesId);

    return editStage?.Acronym === acronym && editStage.Name === stageName &&
      editStage.IsDefault === checked &&
      editStage.ConstructionSiteStageDisciplines.length === disciplineIds.length &&
      editStage.ConstructionSiteStageDisciplines.every(d => {
        return disciplineIds.includes(d.ConstructionSiteDisciplineFk);
      });
  }, [acronym, stageName, checked, disciplinesList, editStage]);

  const filteredDisciplines = useMemo(() => {
    if (search && disciplinesList?.length > 0) {
      return disciplinesList.filter(discipline => {
        return discipline.Name?.toLowerCase()?.includes(search?.toLowerCase());
      })
    }

    return disciplinesList;
  }, [search, disciplinesList]);

  const handleSend = () => {
    setIsSubmit(true);
  }

  const handleSubmit = (values: InputStageProps, errors?: string[]) => {
    if (!values || errors) return;

    const stageExists = !editStage
      ? stages.some(stage => {
        return stage.Acronym?.toLowerCase() === values.acronym?.toLowerCase() ||
          stage.Name?.toLowerCase() === values.stageName?.toLowerCase();
      })
      : stages.filter(stage => {
        return stage.Acronym?.toLowerCase() === values.acronym?.toLowerCase() ||
          stage.Name?.toLowerCase() === values.stageName?.toLowerCase();
      }).length > 1;

    if (stageExists) {
      toastHandler.handler({ description: 'Sigla ou Nome já existente', icon: 'error', title: '' });
      setIsSubmit(false);
      return;
    }

    const disciplineIds = disciplinesList
      .filter(discipline => discipline.IsChecked)
      .map(discipline => discipline.ConstructionSiteDisciplinesId);

    const currentValues: InputStageRequest = {
      ...values,
      isDefault: checked,
      disciplineIds
    };

    const hasOtherDefault = currentValues.isDefault &&
      stages.some(stage => {
        return stage.IsDefault &&
          stage.Acronym?.toLowerCase() !== currentValues.acronym?.toLowerCase() &&
          stage.Name?.toLowerCase() !== currentValues.stageName?.toLowerCase();
      });

    if (hasOtherDefault) {
      setSubmitValues(currentValues);
      setShowConfirmDefault(true);
    } else {
      if (!!editStage) {
        onSubmit(currentValues, editStage.TopicStageId, editStage.Order);
      } else {
        onSubmit(currentValues);
      }
    }

    setIsSubmit(false);
  };

  const handleConfirmDefault = () => {
    if (!submitValues) return;

    if (!!editStage) {
      onSubmit(submitValues, editStage.TopicStageId, editStage.Order);
    } else {
      onSubmit(submitValues);
    }
  };

  const closeConfirmDefault = () => {
    setSubmitValues(null);
    setShowConfirmDefault(false);
  };

  const onCheck = (index: number) => {
    setDisciplinesList(prev => {
      prev[index].IsChecked = !prev[index].IsChecked;
      return [...prev];
    });
  };

  const onCheckAll = (unmarkAll: boolean) => {
    setDisciplinesList(prev => {
      for (const i in prev) {
        if (allowOnlyStagesLinkedToDisciplinesInTopics && unmarkAll) {
          const IsChecked = disciplinesSelectedsFk?.some(disciplineFk => {
            return disciplineFk === prev[i].ConstructionSiteDisciplinesId;
          }) || false;
          prev[i].IsChecked = IsChecked;
        } else {
          if (unmarkAll) prev[i].IsChecked = false;
          else prev[i].IsChecked = true;
        }
      }
      return [...prev];
    });
  };

  const handleStorageList = useCallback(() => {
    const list: IDisciplineDataStage[] = [];

    if (disciplines && disciplines?.length > 0) {
      for (const discipline of disciplines) {
        if (discipline.ConstructionSiteDisciplinesId) {
          const IsChecked = disciplinesSelectedsFk?.some(disciplineFk => {
            return disciplineFk === discipline.ConstructionSiteDisciplinesId;
          }) || false;

          list.push({
            ConstructionSiteDisciplinesId: discipline.ConstructionSiteDisciplinesId,
            IsChecked,
            Name: discipline?.CustomName || discipline?.Discipline?.Name || '',
            Icon: discipline.Discipline?.Icon || '',
          });
        }
      }
    }

    setDisciplinesList(list);
  }, [disciplines, disciplinesSelectedsFk]);

  useEffect(() => {
    handleStorageList();
  }, [disciplines, handleStorageList]);

  useEffect(() => {
    if (checked) {
      onCheckAll(false);
    } else {
      onCheckAll(true);
    }
  }, [checked]);

  useEffect(() => {
    if (editStage && editStage.IsDefault) {
      setChecked(true);
    }
  }, [editStage]);

  return (
    <Modal
      visible={visible}
      width={450}
      footerButtons={(
        <ConfigProvider theme={{
          token: {
            colorBgContainerDisabled: loading ? theme.colors.surface.surfaceOutline : `color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`,
            colorTextDisabled: theme.colors.primary.onPrimary,
            colorPrimary: theme.colors.primary.primary
          }
        }}>
          <BtnContainer>
            <div className="btnContainerLeft">
              <Checkbox
                checked={checked}
                onChange={() => setChecked(prev => !prev)}
                disabled={loading || isDefaultLocked || editStage?.IsDefault}
              />
              <span className="leftText">
                Definir como padrão
              </span>
              <Tooltip
                overlay="Essa etapa aparecerá em todas as disciplinas."
                placement="top"
              >
                <span>
                  <Icon
                    icon="informacaoAdicional"
                    customSize={15}
                    className="iconInfo"
                  />
                </span>
              </Tooltip>
            </div>
            {disabled && (
              <Button type="text" className="actionBtn" disabled>
                {!!editStage ? 'Salvar' : 'Adicionar etapa'}
              </Button>
            )}
            {!disabled && (
              <Button type="primary" className="actionBtn" onClick={handleSend}>
                {loading && (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        rev=""
                        color="white"
                        style={{ fontSize: 12, color: 'white', marginRight: 6 }}
                      />
                    }
                  />
                )}
                {!editStage && (loading ? 'Adicionando...' : 'Criar etapa')}
                {!!editStage && (loading ? 'Salvando...' : 'Salvar')}
              </Button>
            )}
          </BtnContainer>
        </ConfigProvider>
      )}
    >
      <Container>
        <Title>
          <span className="textTitle">Criar nova etapa</span>
          <Icon
            icon="cancelar"
            customSize={12}
            className="iconTitle"
            onClick={onCancel}
          />
        </Title>

        <Content>
          <FormContainer>
            <Form
              schema={schema}
              onSubmit={handleSubmit}
              isSubmited={isSubmit}
              initialValues={{
                acronym: '',
                stageName: editName || '',
              }}
            >
              <div className="formFieldsContainer">
                <span className="containerAcronym">
                  <span className="fieldLabel">
                    Sigla <span className="required">*</span>
                  </span>
                  <Field
                    name='acronym'
                    label=''
                    className="fieldAcronym"
                    value={acronym}
                    onCustomChange={setAcronym}
                    maxLenght={5}
                  />
                </span>
                <span className="containerStageName">
                  <span className="fieldLabel">
                    Nome da Etapa <span className="required">*</span>
                  </span>
                  <Field
                    name='stageName'
                    label=''
                    className="fieldStageName"
                    value={stageName}
                    onCustomChange={setStageName}
                  />
                </span>
              </div>
            </Form>
          </FormContainer>

          <SearchContainer
            disabled={allowOnlyStagesLinkedToDisciplinesInTopics || checked}
          >
            <div className="titleContainer">
              <span className="titleSearch">Disciplinas vinculadas</span>
              <span
                className="titleBtn"
                onClick={() => (allowOnlyStagesLinkedToDisciplinesInTopics || checked)
                  ? undefined
                  : (disciplinesList.every(d => d.IsChecked)
                    ? onCheckAll(true)
                    : onCheckAll(false))
                }
              >
                {disciplinesList.every(d => d.IsChecked)
                  ? 'Desmarcar todas'
                  : 'Selecionar todas'
                }
              </span>
            </div>
            <div className="searchBox">
              <SearchInput
                setSearch={setSearch}
                inputPlaceholder="Pesquisar disciplina..."
                style={{
                  backgroundColor: theme.colors.secondary.secondary,
                }}
              />
            </div>
          </SearchContainer>

          <Main>
            {filteredDisciplines.map((discipline, index) => (
              <ItemDiscipline
                key={`item-${index}`}
                name={discipline.Name}
                checked={discipline.IsChecked}
                loading={loading}
                disabled={allowOnlyStagesLinkedToDisciplinesInTopics || checked}
                tooltipText={checked
                  ? 'Disciplina selecionada pois etapa está marcada como padrão'
                  : ''
                }
                onCheck={() => onCheck(index)}
              />
            ))}
          </Main>
        </Content>
      </Container>

      {showConfirmDefault && (
        <ConfirmDefault
          visible={showConfirmDefault}
          onConfirm={() => handleConfirmDefault()}
          onCancel={closeConfirmDefault}
        />
      )}
    </Modal>
  )
}

export default CreateStage;
