import { FC, useMemo } from "react";
import Tabs, { ActiveTabType, ITabMap, TabsRef } from "../Tabs";
import useActionsContent from "./useActionsContent";
import { INotificationData } from "Data/interfaces/Notification/INotificationResponse";
import Content from "../Content";
import { INotificationsMapAux } from "Data/interfaces/Notification/INotificationsMapAux";

interface IActionsContent {
  tabsRef: React.RefObject<TabsRef>;
  constructionSiteId: number | null;
  notifications: {
    all: INotificationData[];
    allCount: number;
    cs: INotificationData[];
    csCount: number;
  };
  loadingExternalLink: boolean;
  onRedirect: (url: string) => void;
  onMarkAsRead: (readRows: INotificationsMapAux[]) => void;
  onTabChange: (tab: ActiveTabType) => void;
}

const ActionsContent: FC<IActionsContent> = ({
  tabsRef,
  constructionSiteId,
  notifications,
  loadingExternalLink,
  onRedirect,
  onMarkAsRead,
  onTabChange,
}) => {
  useActionsContent({ tabsRef, constructionSiteId });

  const items = useMemo(() => {
    const opts: ITabMap = new Map();

    if (constructionSiteId) {
      opts.set('constructionSite', {
        id: 'constructionSite',
        label: 'Obra',
        isDisabled: false,
        newTagNumber: notifications.csCount,
        children: (
          <Content
            notifications={notifications.cs}
            onRedirect={onRedirect}
            onMarkAsRead={onMarkAsRead}
          />
        ),
      });
    }
    opts.set('allConstructionSites', {
      id: 'allConstructionSites',
      label: 'Todas as Obras',
      isDisabled: false,
      tagNumber: constructionSiteId ? notifications.allCount : undefined,
      newTagNumber: !constructionSiteId ? notifications.allCount : undefined,
      children: (
        <Content
          notifications={notifications.all}
          onRedirect={onRedirect}
          onMarkAsRead={onMarkAsRead}
        />
      ),
    });

    return opts;
  }, [constructionSiteId, notifications, onRedirect, onMarkAsRead]);

  return (
    <Tabs
      ref={tabsRef}
      defaultActiveTab="allConstructionSites"
      options={items}
      loadingExternalLink={loadingExternalLink}
      onTabChange={onTabChange}
    />
  );
}

export default ActionsContent;
