import styled, { css } from "styled-components";

interface IContainer {
  active?: boolean;
  disabled?: boolean;
  isupdate?: boolean;
}

export const Container = styled.div<IContainer>`
  position: relative;
  height: 38px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  
  .input {
    height: 38px;
    padding: 8px 8px 8px 12px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.surface.surface};
    border: none;
    outline: none;
    resize: none;
    overflow: hidden;
    cursor: pointer;

    :hover {
      background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
    }
  }

  .footer {
    position: absolute;
    bottom: 8px;
    height: 32px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 8px;

    .iconSmile {
      border-radius: 6px !important;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
      cursor: pointer;

      :hover {
        background-color: ${({ theme }) => theme.colors.surface.surfaceOutline} !important;
      }
    }

    .footerRight {
      display: flex;
      flex-direction: row;
      align-items: center;

      .btnCancel {
        height: 32px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .btnSubmit {
        height: 32px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 4px;
      }
    }
  }

  :hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
  }

  ${({ active, theme }) => active && css`
    height: auto;

    .input {
      height: auto;
      min-height: 38px;
      max-height: 66px;
      margin-bottom: 52px !important;
      overflow-y: scroll;
      cursor: default;

      :hover {
        background-color: ${theme.colors.surface.surface};
      }
    }
    
    :hover {
      background-color: ${theme.colors.surface.surface};
    }
  `}

  ${({ disabled }) => disabled && css`

    .input {
      background-color: ${({ theme }) => theme.colors.surface.surfaceOutline};
      cursor: not-allowed;

      :hover {
        background-color: ${({ theme }) => theme.colors.surface.surfaceOutline};
      }
    }
    
    :hover {
      background-color: ${({ theme }) => theme.colors.surface.surfaceOutline};
    }
  `}

  ${({ isupdate }) => isupdate && css`
    border: none;
    
    .input {
      padding: 0;
    }

    .footer {
      padding-left: 0;
      padding-right: 0;
    }
  `}
`;
