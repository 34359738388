import styled from "styled-components";
import { Popover } from "antd";

export const PopoverStyled = styled(Popover)`
  cursor: pointer;
`;

export const NotificationButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: ${({ theme }) => `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`};
  }
`;

interface IDropdownNotification {
  multiple?: boolean;
}

export const DropdownNotification = styled.div<IDropdownNotification>`
  width: 400px;
  max-width: 400px;
  height: auto;
  max-height: 534px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding: 0;
`;

export const Header = styled.span`
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  padding-bottom: 0;

  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }

  .closeIcon {
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;  
    cursor: pointer;
  }
`;

export const Main = styled.div`
  width: 100%;
  height: auto;
`;

export const Footer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;

  .btnMarkAllAsRead {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.secondary.onSecondary};
    
    .iconBtnMarkAllAsRead {
      color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
    }
  }

  .btnViewAll {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.primary.onPrimary};
  }
`;
