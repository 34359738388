import styled, { css } from "styled-components";

interface INavContainer {
  iscollapsed?: boolean;
}

export const NavContainer = styled.nav<INavContainer>`
  position: relative;
  left: 225px;
  height: 61px;
  width: calc(100vw - 261px);
  background-color: ${({ theme }) => theme.colors.surface.surface};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.05s ease-out;
  padding-left: 27px;

  .left {
    display: flex;
    align-items: center;

    .buttonCollapse {
      user-select: none;
      cursor: pointer;
    }
  }

  aside {
    display: flex;
    align-items: center; 

    .buttonPremium {
      border-radius: 5px!important;
      padding: 10px 16px!important;
      font-size: 12px!important;
      font-weight: 600;
      line-height: 16px;
      margin-right: 34px;
      border: none!important;
      text-shadow: none!important;

      div {
        display: flex;
        justify-content: space-between;
        
        i {
          margin-right: 12px;
          align-self: center;
          vertical-align: unset;
          width: 16px;
          height: 16px;
        }

        .crownPremium {
          color: ${({ theme }) => theme.colors.premium.onPremium};
        }
      }

    }
  }

  ${({ iscollapsed }) => iscollapsed && css`
    left: 60px;
    width: calc(100vw - 90px);
    transition: all 0.3s ease-out;
    padding-left: 0;
  `}


  @media print {
    display: none;
  }
`;

export const Divider = styled.span`
  margin: 0 20px 0 20px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.surface.surfaceOutline};
  height: 2em;
`;
