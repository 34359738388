import { FC } from "react";
import { INotificationData } from "Data/interfaces/Notification/INotificationResponse";
import { GetAvatar } from "Utils/generateThumbnail";
import { formatTitleHistoryDayjs } from "Utils/DateUtils";
import dayjs from "dayjs";
import { NotificationCategoryEnum } from "Data/enums/Notification";
import { Icon } from "Components/UI";
import { INotificationsMapAux } from "Data/interfaces/Notification/INotificationsMapAux";
import { Wrapper, Container } from "./styles";

interface ICard {
  item: INotificationData;
  onRedirect: (url: string) => void;
  onMarkAsRead: (readRows: INotificationsMapAux[]) => void;
}

const Card: FC<ICard> = ({ item, onRedirect, onMarkAsRead }) => {
  const isActivityType = item.Type === NotificationCategoryEnum.Atividade;

  return (
    <Wrapper>
      <Container onClick={() => onRedirect(item?.ItemLocationUrl || item.ItemUrl)}>
        <span className="leftContainer">
          <GetAvatar
            name={item?.UserName}
            srcName={item?.AvatarFileNameUser || ''}
            size={24}
            fontSize={12}
          />
        </span>
        <span className="rightContainer">
          <span className="rightTextContainer">
            <span className="rightDescription">{item.shortDescription}</span>
            {isActivityType && (
              <span className="rightName">{`Atividade #${item.ItemName}`}</span>
            )}
            {!isActivityType && (
              <span className="rightName">{item.ItemName}</span>
            )}
            <span className="rightDate">
              {formatTitleHistoryDayjs(dayjs(item.DateCreateUtc))}
            </span>
          </span>
          <span className="rightActionButtons">
            <span
              className="rightActionButtonsRead"
              onClick={(e) => {
                e.stopPropagation();
                onMarkAsRead([item]);
              }}
            >
              <Icon
                icon="readNotification"
                customSize={16}
                className="iconReadNotification"
              />
            </span>
            <span className="badgeNewNotification" />
          </span>
        </span>
      </Container>
    </Wrapper>
  )
}

export default Card;
