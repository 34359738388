import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import * as H from 'history';
import { Icon } from '../../UI';
import MenuItem from './MenuItem/MenuItem';
import MenuLogo from './MenuLogo/MenuLogo';
import {
  getDefaultInfoTheme,
  getDomain,
} from 'Store/MultiDomain/MultiDomain.selector';
import { loadImage } from 'Utils/MultidomailUtils';
import { TenantActions } from 'Store/Tenant/Tenant.actions';
import { IRoute, routes } from 'Routes/AuthenticatedRoutes/MainSwitch';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { AuthUtils } from 'Utils/AuthUtils';
import { NotificationActionEnum } from 'Data/enums/Notification';
import MenuItemObras from './MenuItemObras/MenuItemObras';
import { ObraActions } from 'Store/Obra/Obra.actions';
import { NomenclaturaActions } from 'Store/Nomenclatura/Nomenclatura.actions';
import LoadingLeftBar from 'Components/UI/CustomLoading/LoadingLeftBar';
import MenuItemContext from './MenuItemContext/MenuItemContext';
import { ActivitiesActions } from 'Store/Activities/Activities.actions';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import { LeftBarStyled } from './styles';

import logoCoordlyCollapse from 'Static/images/logo-coordly-collapsed.png';

export interface ILeftBar extends RouteComponentProps {
  location: H.Location<H.LocationState>;
  isCollapsed: boolean;
}

const LeftBar: FC<Props> = ({
  location,
  isCollapsed,
  tenants,
  userInfo,
  toggleMenuContext,
  valueConstructionsNotification,
  planFree,
  obrasList,
  notification,
  externalGlobalLoading,
}) => {
  const dispatch = useDispatch();
  const linkHelpUrlFlag = useFeatureFlag('link-help');
  const linkHelpUrl = linkHelpUrlFlag.enabled && typeof linkHelpUrlFlag.value === 'string'
    ? linkHelpUrlFlag.value
    : 'http://help.maletadoengenheiro.com.br/';

  const domain = useSelector(getDomain);
  const defaultDomain = useSelector(getDefaultInfoTheme);

  const [counter, setCounter] = useState(0);
  const [openList, setOpenList] = useState(false);

  const currentTenant = tenants?.find(
    (tenant) => tenant.TenantId === (userInfo?.CurrentTenantContext || AuthUtils.getLoggedUser().currentTenant)
  );

  const isLoading = () => {
    if (!routes.length) return true;
    if (!currentTenant) return true;
    if (!obrasList) return true;
    if (!tenants) return true;
    return false;
  }

  useEffect(() => {
    dispatch(ObraActions.list());
    dispatch(NomenclaturaActions.getNomenclature());
  }, []);

  useEffect(() => {
    if (isLoading() && !externalGlobalLoading) {
      dispatch(ActivitiesActions.externalGlobalLoading(true));
    }
    if (!isLoading() && externalGlobalLoading) {
      dispatch(ActivitiesActions.externalGlobalLoading(false));
    }

    if (currentTenant) {
      dispatch(TenantActions.updateCurrentListTenant(currentTenant));
    }
  }, [isLoading(), currentTenant, externalGlobalLoading]);

  const showDynamicImg = location.pathname.includes('/config/personalization');

  return (
    <LeftBarStyled isCollapsed={`${isCollapsed}`}>
      <MenuLogo
        image={loadImage(domain?.LogoUrl || defaultDomain?.LogoUrl || 'logo-coodly.svg', showDynamicImg)}
        imageCollapse={logoCoordlyCollapse}
        isCollapsed={isCollapsed}
        domain={domain}
      />
      {isLoading() ? (
        <LoadingLeftBar multiple={4} isCollapsed={isCollapsed} />
      ) : (
        <>
          <MenuItemContext
            isCollapsed={isCollapsed}
            toggleMenuContext={toggleMenuContext}
            currentTenant={currentTenant}
            tenants={tenants}
          />

          {routes
            .filter((menu) => menu.title === 'Obras')
            .map((props, i) => {
              return props?.isPermissionUser?.find(
                (permissionUser: number) => permissionUser === userInfo?.CurrentRoleFk
              ) ? (
                <MenuItemObras
                  key={`item-menu-${i}-1`}
                  icon={props.icon}
                  title={props.title ?? ''}
                  path={props.path}
                  obrasList={obrasList}
                  location={location}
                  isCollapsed={isCollapsed}
                  existNotification={props.existNotification}
                  valueConstructionsNotification={valueConstructionsNotification}
                  counter={counter}
                  onCounter={setCounter}
                  openList={openList}
                  onOpenList={setOpenList}
                  notification={notification}
                />
              ) : (
                <></>
              );
            })}
          {routes
            .filter((menu) => menu.title && menu.title !== 'Obras')
            .map((props, i) => {
              return props?.isPermissionUser?.find(
                (permissionUser: number) => permissionUser === userInfo?.CurrentRoleFk
              ) ? (
                <MenuItem
                  key={`item-menu-${i}-2`}
                  icon={props.icon}
                  title={props.title ?? ''}
                  path={props.path}
                  children={props.children as IRoute[]}
                  location={location}
                  isChange={props.isChange}
                  isCollapsed={isCollapsed}
                  showIconPremium={props.isPremium && planFree}
                  existNotification={props.existNotification}
                  valueConstructionsNotification={valueConstructionsNotification}
                  onCounter={setCounter}
                  onOpenList={setOpenList}
                  childrenUserInfo={userInfo}
                  childrenPlanFree={planFree}
                />
              ) : (
                <></>
              );
            })}
          <div
            className="menuItem"
            onClick={() => window.open(linkHelpUrl, '_blank')}
          >
            <Icon
              icon="menuAjuda"
              color={'cinzaPadrao'}
              customSize={isCollapsed ? 12 : 14}
              className="icon"
            />
            <p className="titleMenu">{"Ajuda"}</p>
          </div>
        </>
      )}
    </LeftBarStyled>
  );
};

const mapState = (state: IGlobalReducerState) => ({
  planFree: state.auth.planFree,
  toggleMenuContext: state.tenant.toggleMenuContext,
  tenants: state.tenant.Tenants,
  userInfo: state.auth.userInfo,
  obrasList: state.obra.ObrasList,
  valueConstructionsNotification: state.notification.notificationsMap?.filter((item, index, self) =>
    self.findIndex(itemIndex => itemIndex.ItemId === item.ItemId) === index).reduce((acc, cur) => {
      const allowedActions = [NotificationActionEnum.ArquivoMovido,
      NotificationActionEnum.NovoArquivo, NotificationActionEnum.NovaVersaoArquivo];

      if (allowedActions.includes(cur.Action) && !cur.hasRead) {
        return acc + 1;
      }

      return acc;
    }, 0),
  notification: state.notification.notificationsMap,
  externalGlobalLoading: state.activities.externalGlobalLoading,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ILeftBar;

export default withRouter(connector(LeftBar));
