import { useEffect, useReducer, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveComment,
  getCommentSignalRHasDelete,
  getCommentSignalRHasUpdate,
  getIsActionLoadingComent,
  getIsLoadingComments,
  getIsLoadingShareLinkComment,
  getShareLinkComment,
} from "Store/Activities/Activities.selector";
import { VirtuosoHandle } from "react-virtuoso";
import { ActivitiesActions } from "Store/Activities/Activities.actions";
import { getUserInfo } from "Store/Auth/Auth.selector";
import { IInputData } from "./components/Input";
import { copyToClipboard } from "Utils/TextUtils";
import { history } from "Store";
import {
  IComment,
  ICreateCommentRequest,
} from "Data/interfaces/Activities/IComments";
import toastHandler from "Utils/toastHandler";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import { ITopicDnDResponse } from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { commentsReducer, initialState } from "./comments.reducer";
import { EnumCommentsActionsReducer, IVisibleRange } from "./comments.model";

interface IUseComments {
  updateActivity: ITopicDnDResponse | null;
  workComment: string | null;
}

const useComments = ({ updateActivity, workComment }: IUseComments) => {
  const dispatchRedux = useDispatch();

  const activeComment = useSelector(getActiveComment);
  const isLoadingComments = useSelector(getIsLoadingComments);
  const isActionLoadingComent = useSelector(getIsActionLoadingComent);
  const commentSignalRHasUpdate = useSelector(getCommentSignalRHasUpdate);
  const commentSignalRHasDelete = useSelector(getCommentSignalRHasDelete);
  const shareLinkComment = useSelector(getShareLinkComment);
  const isLoadingShareLinkComment = useSelector(getIsLoadingShareLinkComment);
  const userInfo = useSelector(getUserInfo);
  const theme = useSelector(getTheme);

  const virtuosoRef = useRef<VirtuosoHandle | null>(null);
  const scrollListenerRef = useRef(false);
  const dynamicCommentIdScroll = useRef<string | null>(null);
  const lastCardRef = useRef<HTMLDivElement | null>(null)
  const firstOpen = useRef(true);
  const visibleRangeRef = useRef<IVisibleRange>({ startIndex: 0, endIndex: 0 });
  const [{
    copyingId,
    commentId,
    showDeleteModal,
    sentDelete,
    editComment,
    hasUpdate,
    removingId,
    visibleRange,
  }, dispatchReducer] = useReducer(commentsReducer, {
    ...initialState,
    commentId: workComment,
  });

  const handleCommentId = (commentId: string | null) => {
    dispatchReducer({
      type: EnumCommentsActionsReducer.SET_COMMENT_ID,
      payload: commentId
    });
  };

  const handleVisibleRange = (visibleRange: IVisibleRange) => {
    dispatchReducer({
      type: EnumCommentsActionsReducer.SET_VISIBLE_RANGE,
      payload: visibleRange
    });
  };

  const handleSubmit = (data: IInputData) => {
    if (!updateActivity?.Guid) return;

    const request: ICreateCommentRequest = {
      topicId: updateActivity.Guid,
      data: { commentText: data.text.trim() },
    };
    dispatchRedux(ActivitiesActions.createComment(request));
  };

  const handleScrollToEnd = () => {
    dispatchReducer({
      type: EnumCommentsActionsReducer.SET_HAS_UPDATE,
      payload: false
    });
    const card = lastCardRef.current;
    if (card) {
      card?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      virtuosoRef.current?.scrollToIndex({
        index: activeComment.comments.length - 1,
        behavior: 'smooth',
      });
    }
    dispatchRedux(ActivitiesActions.signalRNewComment(null));
  };

  const handleCopyLink = (comment: IComment) => {
    if (!updateActivity?.Guid) return;

    dispatchReducer({
      type: EnumCommentsActionsReducer.SET_COPYING_ID,
      payload: comment.Id
    });
    const context = userInfo?.CurrentTenantContext;
    const url = `${window.location.origin}/construtora/${context}?redirectUrl=${encodeURIComponent(`${history.location.pathname}?workActivity=${updateActivity.Guid}&workComment=${comment.Id}`)}`;
    dispatchRedux(ActivitiesActions.shareLinkComment({
      topicId: updateActivity?.Guid,
      commentId: comment.Id,
      originalURL: url,
    }));
  };

  const handleDelete = (comment: IComment) => {
    dispatchReducer({
      type: EnumCommentsActionsReducer.SET_EDIT_COMMENT,
      payload: comment
    });
    dispatchReducer({
      type: EnumCommentsActionsReducer.SET_SHOW_DELETE_MODAL,
      payload: true
    });
  };

  const confirmDelete = () => {
    if (!updateActivity?.Guid || !editComment) return;

    dispatchRedux(ActivitiesActions.deleteComment({
      topicId: updateActivity.Guid,
      commentId: editComment.Id,
    }));
    dispatchReducer({
      type: EnumCommentsActionsReducer.SET_SENT_DELETE,
      payload: true
    });
  };

  const handleClose = () => {
    dispatchReducer({
      type: EnumCommentsActionsReducer.SET_SENT_DELETE,
      payload: false
    });
    dispatchReducer({
      type: EnumCommentsActionsReducer.SET_SHOW_DELETE_MODAL,
      payload: false
    });
    dispatchReducer({
      type: EnumCommentsActionsReducer.SET_EDIT_COMMENT,
      payload: null
    });
  };

  useEffect(() => {
    if (
      updateActivity?.Guid &&
      activeComment.comments &&
      activeComment.comments.length > 0 &&
      !commentSignalRHasUpdate
    ) {
      if (workComment) {
        const commentIndex = activeComment.comments.findIndex((comment) => {
          return comment.Id === workComment;
        });
        if (commentIndex !== -1) {
          setTimeout(() => {
            virtuosoRef.current?.scrollToIndex({
              index: commentIndex,
              behavior: 'smooth',
            });
          }, 500);
          setTimeout(() => {
            scrollListenerRef.current = true;
          }, 1500);
          return;
        }
      }
      const lastReadCommentIndex = activeComment.comments.findIndex((comment) => {
        return comment.DateTimeFormated.isAfter(activeComment.lastReadAt);
      });
      if (
        firstOpen.current && lastReadCommentIndex !== -1 &&
        dynamicCommentIdScroll.current !== activeComment.comments[lastReadCommentIndex].Id
      ) {
        firstOpen.current = false;
        dispatchRedux(ActivitiesActions.readComments(updateActivity.Guid));
        setTimeout(() => {
          virtuosoRef.current?.scrollToIndex({
            index: lastReadCommentIndex,
            behavior: 'smooth',
          });
          dynamicCommentIdScroll.current = activeComment.comments[lastReadCommentIndex].Id
          if (
            visibleRangeRef.current.endIndex !== activeComment.comments.length - 1
          ) {
            dispatchReducer({
              type: EnumCommentsActionsReducer.SET_HAS_UPDATE,
              payload: true
            });
          } else {
            dispatchReducer({
              type: EnumCommentsActionsReducer.SET_HAS_UPDATE,
              payload: false
            });
            setTimeout(() => {
              const card = lastCardRef.current;
              if (card) {
                card?.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
            }, 100);
          }
        }, 400);
        setTimeout(() => {
          scrollListenerRef.current = true;
        }, 1000);
        return;
      } else {
        firstOpen.current = false;
        dispatchReducer({
          type: EnumCommentsActionsReducer.SET_HAS_UPDATE,
          payload: false
        });
        dispatchRedux(ActivitiesActions.readComments(updateActivity.Guid));
        virtuosoRef.current?.scrollToIndex({
          index: activeComment.comments.length - 1,
          behavior: 'smooth',
        });
        setTimeout(() => {
          const card = lastCardRef.current;
          if (card) {
            card?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);
        setTimeout(() => {
          scrollListenerRef.current = true;
        }, 1000);
        return;
      }
    }
  }, [
    activeComment.comments,
    workComment,
    commentSignalRHasUpdate,
    updateActivity?.Guid,
  ]);

  useEffect(() => {
    if (!!shareLinkComment) {
      copyToClipboard(shareLinkComment);
      toastHandler.showSuccess('Link copiado com sucesso!');
      dispatchRedux(ActivitiesActions.shareLinkCommentClear());
      dispatchReducer({
        type: EnumCommentsActionsReducer.SET_COPYING_ID,
        payload: null
      });
    }
  }, [shareLinkComment, dispatchRedux]);

  useEffect(() => {
    if (sentDelete && !isActionLoadingComent) {
      handleClose();
    }
  }, [sentDelete, isActionLoadingComent]);

  useEffect(() => {
    visibleRangeRef.current = visibleRange;

    if (
      commentSignalRHasUpdate &&
      commentSignalRHasUpdate?.TopicId === updateActivity?.Guid &&
      activeComment.comments &&
      activeComment.comments.length > 0 &&
      dynamicCommentIdScroll.current !== commentSignalRHasUpdate?.Id &&
      activeComment.comments.some((comment) => {
        return comment.Id === commentSignalRHasUpdate?.Id;
      })
    ) {
      dynamicCommentIdScroll.current = commentSignalRHasUpdate?.Id;
      if (
        visibleRange.endIndex + 1 === activeComment.comments.length - 1 ||
        activeComment.comments.length === 1
      ) {
        virtuosoRef.current?.scrollToIndex({
          index: activeComment.comments.length - 1,
          behavior: 'smooth',
        });
        dispatchRedux(ActivitiesActions.signalRNewComment(null));
      } else {
        dispatchReducer({
          type: EnumCommentsActionsReducer.SET_HAS_UPDATE,
          payload: true
        });
      }
    }

    if (
      commentSignalRHasUpdate &&
      hasUpdate &&
      visibleRange.endIndex === activeComment.comments.length - 1
    ) {
      dispatchReducer({
        type: EnumCommentsActionsReducer.SET_HAS_UPDATE,
        payload: false
      });
      dispatchRedux(ActivitiesActions.signalRNewComment(null));
    }
  }, [
    commentSignalRHasUpdate,
    updateActivity?.Guid,
    activeComment.comments,
    visibleRange,
    hasUpdate,
    dispatchRedux,
  ]);

  useEffect(() => {
    if (commentSignalRHasDelete) {
      dispatchReducer({
        type: EnumCommentsActionsReducer.SET_REMOVING_ID,
        payload: commentSignalRHasDelete.map((comment) => comment.Id),
      });
      setTimeout(() => {
        dispatchReducer({
          type: EnumCommentsActionsReducer.SET_REMOVING_ID,
          payload: null
        });
        dispatchRedux(ActivitiesActions.signalRDeleteCommentClear());
      }, 500);
    }
  }, [
    updateActivity?.Guid,
    commentSignalRHasDelete,
    activeComment.comments,
    dispatchRedux,
  ]);

  return {
    activeComment,
    isLoadingComments,
    isActionLoadingComent,
    isLoadingShareLinkComment,
    userInfo,
    theme,
    virtuosoRef,
    scrollListenerRef,
    copyingId,
    commentId,
    showDeleteModal,
    editComment,
    hasUpdate,
    lastCardRef,
    removingId,
    visibleRange,
    handleSubmit,
    handleCopyLink,
    handleDelete,
    confirmDelete,
    handleClose,
    handleCommentId,
    handleScrollToEnd,
    handleVisibleRange,
  };
};

export default useComments;
