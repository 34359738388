import { FC } from "react";
import { Virtuoso } from "react-virtuoso";
import { history } from "Store";
import AccessPermission from "Utils/AcessPermission";
import { IComments } from "./comments.model";
import useComments from "./useComments";
import LoadingCommentsActivity from "Components/UI/CustomLoading/LoadingCommentsActivity";
import { Icon } from "Components/UI";
import Button from "Components/UI/Button";
import Card from "./components/Card";
import Input from "./components/Input";
import Empty from "./components/Empty";
import DeleteModal from "./components/Delete";
import { formatTitleHistoryDayjs } from "Utils/DateUtils";
import {
  Content,
  DataBadgeContainer,
  InputContainer,
  RemovingCard,
} from "./styles";

const Comments: FC<IComments> = ({
  updateActivity,
  loadingExternalLink,
  workComment,
}) => {
  const {
    activeComment,
    isLoadingComments,
    isActionLoadingComent,
    isLoadingShareLinkComment,
    userInfo,
    theme,
    virtuosoRef,
    scrollListenerRef,
    copyingId,
    commentId,
    showDeleteModal,
    editComment,
    hasUpdate,
    lastCardRef,
    removingId,
    visibleRange,
    handleSubmit,
    handleCopyLink,
    handleDelete,
    confirmDelete,
    handleClose,
    handleCommentId,
    handleScrollToEnd,
    handleVisibleRange,
  } = useComments({ updateActivity, workComment });

  const loading = isLoadingComments || loadingExternalLink;
  const createPermission = !AccessPermission.createCommentPermission(
    userInfo,
    updateActivity?.TopicAssignedUsers,
  );

  return (
    <>
      <Content loading={loading}>
        {loading && (
          <LoadingCommentsActivity multiple={8} />
        )}
        {!loading && activeComment.comments.length > 0 && (
          <>
            <Virtuoso
              ref={virtuosoRef}
              style={{ flex: 1 }}
              totalCount={activeComment.comments.length}
              data={activeComment.comments}
              itemContent={(index, comment) => {
                const isFirst = index === 0;
                const isLast = index === activeComment.comments.length - 1;
                const removing = removingId?.includes(comment.Id);
                const isResponsible = updateActivity?.TopicAssignedUsers?.some(user => {
                  return user?.Id === comment.CreationAuthorFk;
                });

                return (
                  <RemovingCard
                    key={comment.Id}
                    ref={(el) => isLast ? lastCardRef.current = el : null}
                    removing={removing}
                  >
                    <Card
                      activityId={updateActivity?.Guid}
                      item={comment}
                      first={isFirst}
                      last={isLast}
                      isResponsible={isResponsible}
                      loadingShareLink={copyingId === comment.Id &&
                        isLoadingShareLinkComment
                      }
                      active={commentId === comment.Id}
                      theme={theme}
                      isOwner={userInfo?.Id && comment?.CreationAuthorFk
                        ? userInfo.Id === comment.CreationAuthorFk
                        : false
                      }
                      onCopyLink={handleCopyLink}
                      onDelete={handleDelete}
                      onScrollToEnd={handleScrollToEnd}
                    />
                  </RemovingCard>
                )
              }}
              alignToBottom={true}
              followOutput="smooth"
              onScroll={() => {
                if (!!commentId && scrollListenerRef.current) {
                  scrollListenerRef.current = false;
                  history.push('?');
                  handleCommentId(null);
                }
              }}
              rangeChanged={handleVisibleRange}
            />
            <DataBadgeContainer>
              <span className="badgeText">
                {(activeComment.comments[visibleRange.startIndex + 1] &&
                  activeComment.comments[visibleRange.startIndex].DateTimeTitle ===
                  activeComment.comments[visibleRange.startIndex + 1].DateTimeTitle
                )
                  ? formatTitleHistoryDayjs(activeComment.comments[visibleRange.startIndex + 1].DateTimeFormated)
                  : formatTitleHistoryDayjs(activeComment.comments[visibleRange.startIndex].DateTimeFormated)
                }
              </span>
            </DataBadgeContainer>
          </>
        )}
        {!loading && activeComment.comments.length === 0 && (
          <Empty />
        )}
        {hasUpdate && (
          <div className="containerReload">
            <Button
              type="primary"
              className="btnReload"
              onClick={handleScrollToEnd}
            >
              Novos comentários
              <Icon
                icon="downArrow"
                customSize={16}
                className="iconDownArrow"
              />
            </Button>
          </div>
        )}
      </Content>
      <InputContainer>
        <Input
          disabled={createPermission}
          loading={isActionLoadingComent}
          onSubmit={handleSubmit}
        />
      </InputContainer>
      {showDeleteModal && editComment && (
        <DeleteModal
          visible={showDeleteModal}
          loading={isActionLoadingComent}
          onConfirm={confirmDelete}
          onCancel={handleClose}
        />
      )}
    </>
  );
};

export default Comments;
