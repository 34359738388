import { RefObject, useEffect } from "react";
import { TabsRef } from "../Tabs";

interface IUseActionsContent {
  tabsRef: RefObject<TabsRef>;
  constructionSiteId: number | null;
}

const useActionsContent = ({
  tabsRef,
  constructionSiteId,
}: IUseActionsContent) => {

  useEffect(() => {
    if (constructionSiteId && tabsRef.current) {
      tabsRef.current.onActiveTab("constructionSite");
    } else if (tabsRef.current) {
      tabsRef.current.onActiveTab("allConstructionSites");
    }
  }, [constructionSiteId, tabsRef]);

  return {};
}

export default useActionsContent;
