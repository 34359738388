import { useCallback, useEffect, useState } from "react";
import {
  ITopicCommunicationType,
  ITopicDnDResponse,
  ITopicPoint,
  ITopicStatus,
} from "Data/interfaces/Activities/IDragAndDropDataFormat";
import { ITopicCreateRequest } from "Data/interfaces/Activities/ITopicCreateRequest";
import { useDispatch, useSelector } from "react-redux";
import { history } from "Store";
import { isExpiredDate } from "Utils/DateUtils";
import { copyToClipboard } from "Utils/TextUtils";
import { TypeCustomError } from ".";
import { ICustomOption } from "./useOptionsSelect";
import { ActivitiesActions } from "Store/Activities/Activities.actions";
import {
  IUpdateActivityRequest,
  TypePathUpdateData,
  TypeUpdateStatus,
  TypeValueUpdateData,
} from "Data/interfaces/Activities/IUpdateActivityRequest";
import { IUserData } from "Data/interfaces/User/IUserData";
import { IDisciplineResponse } from "Data/interfaces/Obra/IDisciplineResponse";
import useQuery from "Hooks/useQuery";
import dayjs from "dayjs";
import { IUserInforResponse } from "Data/interfaces/Auth/IUserInfoResponse";
import { compareIfIdenticalArrays } from "Utils/ActivitiesUtils";
import { getAttachmentsForCreate } from "Store/Activities/Activities.selector";
import { InputStageRequest } from "Pages/EditObra/components/Stages/stages.model";
import { ObraActions } from "Store/Obra/Obra.actions";
import {
  ICreateStageRequest,
  IStage,
  IUpdateStageRequest,
} from "Data/interfaces/Obra/IObraStages";
import { useFeatureFlag } from "Hooks/useFeatureFlag";

interface IUseShowCreateActivity {
  loadingExternalLink: boolean;
  loading: boolean;
  loadingUpdate: boolean;
  loadingDelete: boolean;
  updateActivity: ITopicDnDResponse | null;
  editStage: boolean;
  csId: number;
  topicStatus: ITopicStatus[];
  topicStages: IStage[];
  allowOnlyStagesLinkedToDisciplinesInTopics?: boolean;
  topicPoints: ITopicPoint[];
  topicCommunicationType: ITopicCommunicationType[];
  typeOptions: ICustomOption[];
  updateStatus: TypeUpdateStatus;
  getOld: boolean;
  newActivityGuid?: string;
  userInfo?: IUserInforResponse;
  createStageLoading?: boolean;
  onOpenShowActivity: (activitieId: string) => void;
  onCancel: () => void;
}

export interface ITopicCreateRequestForm {
  title: string;
  description: string | null;
  priority: number | null;
  visibility: number;
  dueDate: Date | null;
  ConstructionSiteFk: number;
  statusFk?: number;
  typeFk?: number;
  stageFk?: string;
  pointsFk?: string[];
  communicationTypeFk?: string;
  assignedUsersFk?: string[];
  constructionSiteDisciplinesFk?: number[];
  newPoints?: string[];
  newCommunicationType?: string;
  newStage?: string;

  // provisório
  tumb?: string;
}

const useShowCreateActivity = ({
  loading,
  loadingExternalLink,
  loadingUpdate,
  loadingDelete,
  editStage,
  updateActivity,
  csId,
  topicStatus,
  topicStages,
  allowOnlyStagesLinkedToDisciplinesInTopics,
  topicPoints,
  topicCommunicationType,
  typeOptions,
  updateStatus,
  getOld,
  newActivityGuid,
  userInfo,
  createStageLoading,
  onOpenShowActivity,
  onCancel,
}: IUseShowCreateActivity) => {
  const dispatch = useDispatch();
  const attachmentsForCreate = useSelector(getAttachmentsForCreate);
  const query = useQuery();

  const stagesFeatureActivityValidateFlag = useFeatureFlag('stages-feature-activity-validate');
  const stagesFeatureActivityValidate = stagesFeatureActivityValidateFlag.enabled && stagesFeatureActivityValidateFlag.value === 'test';

  const [isSubmit, setIsSubmit] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [defaultValues, setDefaultValues] = useState<ITopicCreateRequestForm>({} as ITopicCreateRequestForm);
  const [titleValue, setTitleValue] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [showCreateStage, setShowCreateStage] = useState(false);
  const [createStageName, setCreateStageName] = useState('');
  const [sentRequestStage, setSentRequestStage] = useState(false);
  const [errors, setErrors] = useState<TypeCustomError[]>([]);
  const [selectedDisciplines, setSelectedDisciplines] = useState<number[]>([]);

  const copyInviteLink = (Guid: string) => {
    const context = userInfo?.CurrentTenantContext;
    copyToClipboard(
      `${window.location.origin}/construtora/${context}?redirectUrl=${encodeURIComponent(`${history.location.pathname}?workActivity=${Guid}`)}`
    );
    setShowToolTip(true);
    setTimeout(() => {
      setShowToolTip(false);
    }, 1200);
  }

  const submit = (values: ITopicCreateRequestForm) => {
    if (!updateActivity) {
      if (!titleValue) {
        handleCustomError('title');
      }
      if (!values?.constructionSiteDisciplinesFk) {
        handleCustomError('discipline');
      }
      if (!values?.pointsFk) {
        handleCustomError('point');
      }

      let dateIsInvalid = false;
      if (values?.dueDate && isExpiredDate(values.dueDate)) {
        dateIsInvalid = true;
        handleCustomError('date');
      }

      if (
        values &&
        titleValue &&
        values?.constructionSiteDisciplinesFk &&
        values?.pointsFk &&
        !dateIsInvalid
      ) {
        const request: ITopicCreateRequest = {
          title: titleValue,
          description: values?.description,
          priority: values?.priority,
          visibility: values.visibility,
          dueDate: values?.dueDate,
          ConstructionSiteFk: values?.ConstructionSiteFk || csId,
          statusFk: values?.statusFk,
          typeFk: values?.typeFk,
          assignedUsersFk: values?.assignedUsersFk,
          constructionSiteDisciplinesFk: values.constructionSiteDisciplinesFk,
        };

        if (values.stageFk && values.stageFk.length > 0) {
          if (stagesFeatureActivityValidate) {
            const filteredStages = allowOnlyStagesLinkedToDisciplinesInTopics
              ? topicStages.filter((stage) => {
                return stage.IsDefault ||
                  stage.ConstructionSiteStageDisciplines.some((discipline) => {
                    return values?.constructionSiteDisciplinesFk?.some((disciplineFk) => {
                      return discipline.ConstructionSiteDisciplineFk === disciplineFk;
                    })
                  })
              })
              : topicStages;
            const stagesRecords = filteredStages.reduce((prev, stage) => {
              const key = `${stage.Acronym} | ${stage.Name}`;
              return { [key]: stage.TopicStageId, ...prev }
            }, {} as Record<string, number>);
            const formatStageValue: string = Array.isArray(values.stageFk)
              ? values.stageFk[0]
              : values.stageFk;
            const newStageValue = stagesRecords[formatStageValue || ''];

            if (newStageValue) {
              request.stageFk = newStageValue;
            }
          } else {
            const formatStageValue: string = Array.isArray(values.stageFk)
              ? values.stageFk[0]
              : values.stageFk;
            const newStageValue = topicStages.find(stage => {
              return formatStageValue.toLowerCase() === stage.Name.toLowerCase();
            });

            if (newStageValue) {
              request.stageFk = newStageValue.TopicStageId;
            } else {
              delete request.stageFk;
              request.newStage = formatStageValue;
            }
          }
        }

        if (values.communicationTypeFk && values.communicationTypeFk.length > 0) {
          const formatCommunicationValue: string = Array.isArray(values.communicationTypeFk)
            ? values.communicationTypeFk[0]
            : values.communicationTypeFk;
          const newCommunicationValue = topicCommunicationType.find(communication => {
            return formatCommunicationValue.toLowerCase() === communication.Name.toLowerCase();
          });

          if (newCommunicationValue) {
            request.communicationTypeFk = newCommunicationValue.TopicCommunicationTypeId;
          } else {
            delete request.communicationTypeFk;
            request.newCommunicationType = formatCommunicationValue;
          }
        }

        if (values.pointsFk && values.pointsFk?.length > 0) {
          const pointsFk: number[] = [];
          const newPoints: string[] = [];

          values.pointsFk?.map((value) => {
            const newPointsValue = topicPoints.find(point => {
              return value.toLowerCase() === point.Name.toLowerCase();
            });
            if (newPointsValue) {
              return pointsFk.push(newPointsValue.TopicPointId);
            } else {
              return newPoints.push(value.toString());
            }
          });

          if (pointsFk.length > 0) {
            request.pointsFk = pointsFk;
          } else {
            delete request.pointsFk;
          }
          if (newPoints.length > 0) {
            request.newPoints = newPoints;
          }
        }

        if (attachmentsForCreate.length > 0) {
          request.filesFk = attachmentsForCreate.map(attachment => attachment.FileFk);
        }

        setIsSending(true);
        dispatch(ActivitiesActions.createActivity(request));
      }
    }

    return setIsSubmit(false);
  };

  const dynamicUpdateSubmit = (
    path: TypePathUpdateData,
    values: TypeValueUpdateData,
  ) => {
    if (updateActivity) {
      const request: IUpdateActivityRequest = {
        Guid: updateActivity.Guid,
        updateData: [],
      };

      const populatedRequest = (
        path: TypePathUpdateData,
        values: TypeValueUpdateData,
      ) => {
        request.updateData.push({
          path: path,
          op: 'replace',
          value: values,
        })
      }

      switch (path) {
        case 'Title':
          const currentTitle = updateActivity.Title;

          if (!values) {
            return handleCustomError('title');
          }

          if (currentTitle !== values) {
            populatedRequest(path, values);
          }
          break;

        case 'Description':
          const currentDescription = updateActivity.Description;

          if (currentDescription !== values) {
            populatedRequest(path, values);
          }
          break;

        case 'Priority':
          const currentPriority = updateActivity.Priority;

          if (currentPriority !== values) {
            populatedRequest(path, values);
          }
          break;

        case 'Visibility':
          const currentVisibility = updateActivity.Visibility;

          if (currentVisibility !== values) {
            populatedRequest(path, values);
          }
          break;

        case 'StageFk':
          if (stagesFeatureActivityValidate) {
            const filteredStages = allowOnlyStagesLinkedToDisciplinesInTopics
              ? topicStages.filter((stage) => {
                return stage.IsDefault ||
                  stage.ConstructionSiteStageDisciplines.some((discipline) => {
                    return defaultValues.constructionSiteDisciplinesFk?.some((disciplineFk) => {
                      return discipline.ConstructionSiteDisciplineFk === disciplineFk;
                    })
                  })
              })
              : topicStages;
            const stagesRecords = filteredStages.reduce((prev, stage) => {
              const key = `${stage.Acronym} | ${stage.Name}`;
              return { [key]: stage.TopicStageId, ...prev }
            }, {} as Record<string, number>);
            const currentStage = updateActivity.Stage?.TopicStageId;
            const formatStageValue: string | undefined = (Array.isArray(values) && values.length > 0)
              ? values[0].toString()
              : undefined;
            if (!formatStageValue) {
              if (formatStageValue !== currentStage) {
                populatedRequest('StageFk', null);
              }
            } else {
              const newStageValue = stagesRecords[formatStageValue || ''];
              if (newStageValue !== currentStage) {
                if (newStageValue) {
                  populatedRequest('StageFk', newStageValue);
                }
              }
            }
          } else {
            const currentStage = updateActivity.Stage?.Name;
            const formatStageValue: string | undefined = (Array.isArray(values) && values.length > 0)
              ? values[0].toString()
              : undefined;

            if (!formatStageValue) {
              if (formatStageValue !== currentStage) {
                populatedRequest('StageFk', null);
              }

            } else {
              const newStageValue = topicStages.find(stage => {
                return formatStageValue.toLowerCase() === stage.Name.toLowerCase();
              });

              if ((currentStage?.toLowerCase() !== newStageValue?.Name?.toLowerCase())
                || (currentStage?.toLowerCase() !== formatStageValue?.toLowerCase())
              ) {
                if (newStageValue) {
                  populatedRequest('StageFk', newStageValue.TopicStageId);

                } else {
                  populatedRequest('NewStage', formatStageValue);
                }
              }
            }
          }
          break;

        case 'CommunicationTypeFk':
          const currentCommunication = updateActivity.CommunicationTypes?.Name;
          const formatCommunicationValue: string | undefined = (Array.isArray(values) && values.length > 0)
            ? values[0].toString()
            : undefined;

          if (!formatCommunicationValue) {
            if (formatCommunicationValue !== currentCommunication) {
              populatedRequest('CommunicationTypeFk', null);
            }

          } else {
            const newCommunicationValue = topicCommunicationType.find(communication => {
              return formatCommunicationValue.toLowerCase() === communication.Name.toLowerCase();
            });

            if ((currentCommunication?.toLowerCase() !== newCommunicationValue?.Name?.toLowerCase())
              || (currentCommunication?.toLowerCase() !== formatCommunicationValue?.toLowerCase())
            ) {
              if (newCommunicationValue) {
                populatedRequest('CommunicationTypeFk', newCommunicationValue.TopicCommunicationTypeId);

              } else {
                populatedRequest('NewCommunicationType', formatCommunicationValue);
              }
            }
          }
          break;

        case 'PointsFk':
          if (Array.isArray(values) && values.length > 0) {
            const currentPoints = updateActivity.TopicPoints.map(points => points.Name);
            const pointsFk: number[] = [];
            const newPoints: string[] = [];

            if (!compareIfIdenticalArrays(currentPoints, values as string[])) {
              values?.forEach((value: string | number) => {
                const newPointsValue = topicPoints.find(point => {
                  return value.toString().toLowerCase() === point.Name.toLowerCase();
                });
                if (newPointsValue) {
                  return pointsFk.push(newPointsValue.TopicPointId);

                } else {
                  return newPoints.push(value.toString());
                }
              });

              if (pointsFk.length > 0) {
                populatedRequest('PointsFk', pointsFk);
              }
              if (newPoints.length > 0) {
                populatedRequest('NewPoints', newPoints);
              }
            }

          } else {
            return handleCustomError('point');
          }
          break;

        case 'DueDate':
          const currentDueDate = updateActivity.DueDate;

          if (values && isExpiredDate(values as any)) {
            return handleCustomError('date');
          }

          if (!compareDates(currentDueDate, values as any)) {
            populatedRequest(path, values);
          }
          break;

        case 'AssignedUsersFk':
          const currentAssignedUsersFk =
            updateActivity?.TopicAssignedUsers?.reduce((array: string[], user: IUserData) => {
              if (user.Id !== undefined) {
                array.push(user.Id);
              }
              return array;
            }, []);

          if (!compareIfIdenticalArrays(currentAssignedUsersFk, values as string[])) {
            populatedRequest(path, values);
          }
          break;

        case 'ConstructionSiteDisciplinesFk':
          if (Array.isArray(values) && values.length > 0) {
            const currentConstructionSiteDisciplinesFk =
              updateActivity?.ConstructionSiteDisciplines?.reduce((array: number[], discipline: IDisciplineResponse) => {
                if (discipline.ConstructionSiteDisciplinesId !== undefined) {
                  array.push(discipline.ConstructionSiteDisciplinesId);
                }
                return array;
              }, []);

            if (!compareIfIdenticalArrays(currentConstructionSiteDisciplinesFk, values as number[])) {
              populatedRequest(path, values);
            }

          } else {
            return handleCustomError('discipline');
          }
          break;

        case 'TypeFk':
          const currentTypeFk = updateActivity.Type.TopicTypeId;

          if (currentTypeFk !== values) {
            populatedRequest(path, values);
          }
          break;

        case 'StatusFk':
          const currentStatusFk = updateActivity.Status.TopicStatusId;

          if (currentStatusFk !== values) {
            populatedRequest(path, values);
          }
          break;

        default:
          populatedRequest(path, values || null);
          break;
      }

      if (request.updateData.length <= 0) {
        return;
      }

      dispatch(ActivitiesActions.updateActivity(request));
    }
  };

  const compareDates = useCallback((
    currentDate: Date | string | null,
    newDate: Date | string | null
  ) => {
    let formatCurrentDate = null;
    let formatNewDate = null;

    if (currentDate) {
      formatCurrentDate = dayjs(currentDate);
    }
    if (newDate) {
      formatNewDate = dayjs(newDate);
    }

    if (dayjs.isDayjs(formatCurrentDate) && dayjs.isDayjs(formatNewDate)) {
      return dayjs(formatCurrentDate).isSame(formatNewDate);
    }

    return formatCurrentDate === formatNewDate;
  }, []);

  const handleTitleValue = (value: string) => {
    setTitleValue(value);
  };

  const handleIsSubmit = (value: boolean) => {
    setIsSubmit(value);
  };

  const openCancelModal = () => {
    setShowCancelModal(true);
  };

  const closeCancelModal = () => {
    setShowCancelModal(false);
  };

  const confirmCancelShowCreateActivity = () => {
    onCancel();
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDeleteActivity = () => {
    if (updateActivity) {
      dispatch(ActivitiesActions.deleteActivity(updateActivity.Guid));
      setIsDeleting(true);
    }
  };

  const handleCustomError = (error: TypeCustomError) => {
    setErrors(prev => {
      if (prev.includes(error)) {
        return prev;
      } else {
        return [...prev, error];
      }
    });
  };

  const handleClearCustomError = (error: TypeCustomError) => {
    setErrors(prev => {
      if (prev.includes(error)) {
        return prev.filter(state => state !== error);
      } else {
        return prev;
      }
    });
  };

  const clearSent = () => {
    setIsSending(false);
    setIsDeleting(false);
  }

  const showCreateStageModal = (value: string) => {
    setCreateStageName(value);
    setShowCreateStage(true);
  }

  const closeCreateStageModal = () => {
    setCreateStageName('');
    setShowCreateStage(false);
  }

  const handleSelectedDisciplines = (selectedDisciplines: number[]) => {
    setSelectedDisciplines(selectedDisciplines);
  }

  const handleCreateStage = (
    values: InputStageRequest,
    topicStageId?: number,
    order?: number,
  ) => {
    if (!showCreateStage || !values) return;

    if (topicStageId) {
      const request: IUpdateStageRequest = {
        constructionStieId: csId,
        stageId: topicStageId,
        stage: {
          acronym: values.acronym,
          name: values.stageName,
          constructionSiteDisciplineIds: values.disciplineIds,
          isDefault: values.isDefault,
          order: order,
        },
      };
      dispatch(ObraActions.updateStages(request));
    } else {
      const request: ICreateStageRequest = {
        constructionStieId: csId,
        stage: {
          acronym: values.acronym,
          name: values.stageName,
          constructionSiteDisciplineIds: values.disciplineIds,
          isDefault: values.isDefault,
        },
      };
      dispatch(ObraActions.createStages(request));
    }
    setSentRequestStage(true);
  }

  const renderEmpty = (msg?: string) => <>{msg}</>;

  useEffect(() => {
    if (!loadingExternalLink && !loading && !loadingUpdate && userInfo) {
      if (updateActivity) {
        setTitleValue(updateActivity.Title);
        setDefaultValues(prevState => ({
          ...prevState,
          description: updateActivity?.Description || null,
          priority: updateActivity?.Priority || null,
          visibility: updateActivity.Visibility,
          dueDate: updateActivity.DueDate,
          ConstructionSiteFk: updateActivity.ConstructionSiteFk || csId,
          statusFk: updateActivity?.Status?.TopicStatusId,
          typeFk: updateActivity?.Type?.TopicTypeId,
          stageFk: stagesFeatureActivityValidate
            ? ((updateActivity?.Stage?.Acronym || updateActivity?.Stage?.Name)
              ? `${updateActivity?.Stage?.Acronym || ''}${(updateActivity?.Stage?.Acronym || updateActivity?.Stage?.Name) ? ' | ' : ''}${updateActivity?.Stage?.Name || ''}`
              : undefined)
            : updateActivity?.Stage?.Name,
          pointsFk: updateActivity?.TopicPoints?.map(point => point.Name),
          communicationTypeFk: updateActivity?.CommunicationTypes?.Name,
          assignedUsersFk: updateActivity?.TopicAssignedUsers?.map(user => user.Id || ''),
          constructionSiteDisciplinesFk: updateActivity?.ConstructionSiteDisciplines?.map(discipline => discipline.ConstructionSiteDisciplinesId),
        }));
        if (updateActivity.ConstructionSiteDisciplines.length <= 0) {
          handleCustomError('discipline');
        }
        if (
          stagesFeatureActivityValidate &&
          updateActivity?.Stage?.Name &&
          !updateActivity?.Stage?.Acronym
        ) {
          handleCustomError('stage');
        } else {
          handleClearCustomError('stage');
        }
      } else {
        setDefaultValues(prevState => ({
          ...prevState,
          typeFk: Number(typeOptions[0]?.id) || 0,
          visibility: 1,
          ConstructionSiteFk: csId,
          statusFk: topicStatus?.find(status => status.Order === 1)?.TopicStatusId,
        }));
      }
      if (getOld) {
        dispatch(ActivitiesActions.clearGetOld());
      }
      if (editStage) {
        dispatch(ActivitiesActions.setEditStages(false));
      }
    }
  }, [
    updateActivity,
    loadingExternalLink,
    loading,
    getOld,
    csId,
    typeOptions,
    topicStatus,
    dispatch,
    loadingUpdate,
    userInfo,
    editStage,
    stagesFeatureActivityValidate,
  ]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if ((isSending || isDeleting) && !loading && !isSubmit && !loadingDelete) {
      if (isSending && newActivityGuid) {
        clearSent();
        onOpenShowActivity(newActivityGuid);
      }
      if (isDeleting) {
        if (query.get('workActivity')) {
          history.push(`?`);
        }
        timeout = setTimeout(() => {
          clearSent();
          confirmCancelShowCreateActivity();
        }, 50);

        return () => {
          clearTimeout(timeout);
        }
      }
    }
  }, [loading, isSending, loadingDelete, isDeleting, newActivityGuid]);

  useEffect(() => {
    if (updateStatus && updateStatus !== 'atualizando') {
      const timeout = setTimeout(() => {
        dispatch(ActivitiesActions.changeUpdateStatus(null));
      }, 2000);

      return () => {
        clearTimeout(timeout);
      }
    }
  }, [updateStatus]);

  useEffect(() => {
    if (sentRequestStage && !createStageLoading) {
      closeCreateStageModal();
    }
  }, [sentRequestStage, createStageLoading]);

  return {
    isSubmit,
    titleValue,
    defaultValues,
    showCancelModal,
    showDeleteModal,
    showToolTip,
    errors,
    showCreateStage,
    createStageName,
    sentRequestStage,
    selectedDisciplines,
    showCreateStageModal,
    closeCreateStageModal,
    handleCreateStage,
    handleTitleValue,
    copyInviteLink,
    submit,
    handleIsSubmit,
    dynamicUpdateSubmit,
    confirmCancelShowCreateActivity,
    confirmDeleteActivity,
    openCancelModal,
    openDeleteModal,
    handleSelectedDisciplines,
    handleClearCustomError,
    renderEmpty,
    handleCustomError,
    closeCancelModal,
    closeDeleteModal,
  };
}

export default useShowCreateActivity;
